import { Editor, Transforms, Element as SlateElement, Node } from "slate";
import { SlateEditorFormatType } from "../../../enums/SlateEditorFormatType";
import { CustomElement, ImageElement } from "./SlateEditorTypes";
import { ReactEditor } from "slate-react";

export const SlateCustomEditor = {
    LIST_TYPES: [
        SlateEditorFormatType.NumberedList,
        SlateEditorFormatType.BulletedList,
    ],
    TEXT_ALIGN_TYPES: [
        SlateEditorFormatType.AlignLeft,
        SlateEditorFormatType.AlignCenter,
        SlateEditorFormatType.AlignRight,
        SlateEditorFormatType.AlignJustify,
    ],

    isMarkActive(editor: Editor, format: SlateEditorFormatType): boolean {
        const marks = Editor.marks(editor);
        if (!marks) return false;

        for (const [key, mark] of Object.entries(marks)) {
            if (key !== format) continue;

            return mark === true;
        }

        return false;
    },

    toggleMark(editor: Editor, format: SlateEditorFormatType): void {
        const isActive = this.isMarkActive(editor, format);

        if (isActive) Editor.removeMark(editor, format);
        else Editor.addMark(editor, format, true);
    },

    isBlockActive(editor: Editor, format: SlateEditorFormatType): boolean {
        const [match] = Editor.nodes(editor, {
            match: (n: any) => n.type === format,
        });

        return !!match;
    },

    toggleBlock(editor: Editor, format: SlateEditorFormatType): void {
        const isActive = this.isBlockActive(editor, format);
        const isList = this.LIST_TYPES.includes(format);

        Transforms.unwrapNodes(editor, {
            match: (n: Node) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                this.LIST_TYPES.includes(n.type) &&
                !this.TEXT_ALIGN_TYPES.includes(format),
            split: true,
        });

        let newProperties: Partial<SlateElement>;

        if (this.TEXT_ALIGN_TYPES.includes(format)) {
            newProperties = {
                align: isActive ? undefined : format,
            };
        } else {
            newProperties = {
                type: isActive
                    ? SlateEditorFormatType.Paragraph
                    : isList
                    ? SlateEditorFormatType.ListItem
                    : format,
            };
        }
        Transforms.setNodes<SlateElement>(editor, newProperties);

        if (!isActive && isList) {
            const block: CustomElement = { type: format, children: [] };
            Transforms.wrapNodes(editor, block);
        }
    },

    insertImage(
        editor: Editor,
        imgUrl: string,
        imgAlt: string,
        imgHeading: string
    ): void {
        const image: ImageElement = {
            type: SlateEditorFormatType.Image,
            imgUrl,
            imgAlt,
            imgHeading,
            children: [{ text: "" }],
        };

        Transforms.insertNodes(editor, image);
        Transforms.insertNodes(editor, {
            type: SlateEditorFormatType.Paragraph,
            children: [{ text: "" }],
        });
    },

    async deleteImage(editor: Editor, element: CustomElement): Promise<void> {
        const path = ReactEditor.findPath(editor, element);

        Transforms.removeNodes(editor, { at: path });
    },
};
