import { ReactComponent as ImageIcon } from "../../../../../../../assets/resources/icons/image.svg";

export interface SlateEditorToolbarImageBtnProps {
    onClick: (event: React.MouseEvent) => void;
}

const SlateEditorToolbarImageBtn = ({
    onClick,
}: SlateEditorToolbarImageBtnProps) => {
    return (
        <>
            <button className="SlateEditorToolbarImageBtn" onClick={onClick}>
                <ImageIcon className="SlateEditorToolbarImageBtn__icon"></ImageIcon>
            </button>
        </>
    );
};

export default SlateEditorToolbarImageBtn;
