import IEvent from "../../../../interfaces/IEvent";
import { ReactComponent as CrossIcon } from "../../../../assets/resources/icons/cross.svg";
import SmallIconButton from "../../../../components/common/buttons/smallIconButton/SmallIconButton";

export interface StartseiteNextEventsModalProps {
    event: IEvent | null;
    setShow: Function;
}

const StartseiteNextEventsModal = ({
    event,
    setShow,
}: StartseiteNextEventsModalProps) => {
    if (event === null) {
        return <></>;
    }

    const dateObj = new Date(event.date * 1000);

    const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setShow(false);
    };

    return (
        <>
            <div className="StartseiteNextEventsModal__bg">
                <div className="StartseiteNextEventsModal shadow">
                    <div className="row">
                        <div className="col-12">
                            <h2>{event.title}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="b0-sb text-center">
                                Datum: {dateObj.toLocaleDateString()}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>{event.description}</p>
                        </div>
                    </div>
                    <div className="StartseiteNextEventsModal__closeBtn">
                        <SmallIconButton
                            description="Popup schließen"
                            onClick={closeModal}
                            Icon={CrossIcon}
                        ></SmallIconButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StartseiteNextEventsModal;
