import xss from "xss";
import React, { useRef, useState } from "react";

import IContactSend from "../../interfaces/IContactSend";
import KontaktService from "../../services/kontaktService/KontaktService";

import { ButtonType } from "../../enums/ButtonType";
import Input from "../../components/common/forms/input/Input";
import Textarea from "../../components/common/forms/textarea/Textarea";
import IconButton from "../../components/common/buttons/iconButton/IconButton";
import HorizontalLine from "../../components/common/horizontalLine/HorizontalLine";

import { ReactComponent as TrashIcon } from "./../../assets/resources/icons/trash.svg";
import { ReactComponent as EnvelopesImage } from "./../../assets/resources/images/envelopes.svg";
import HCaptchaComponent from "../../components/common/forms/hcaptchaComponent/HCaptchaComponent";
import { ReactComponent as PaperPlaneIcon } from "./../../assets/resources/icons/paper-plane.svg";
import { useAppDispatch } from "../../hooks";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { AlertVariant } from "../../enums/AlertVariant";

const Kontakt = () => {
    const dispatch = useAppDispatch();

    const formElement = useRef<HTMLFormElement | null>(null);

    const initialFormDataValue = {
        user_first_name: "",
        user_last_name: "",
        user_email: "",
        subject: "",
        content: "",
        token: "",
    };

    const [formData, setFormData] =
        useState<IContactSend>(initialFormDataValue);

    const onFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (formData.token === null || formData.token === "") {
            console.warn("Not completed Captcha!");

            dispatch(
                alertAdded({
                    text: "Bitte bestätigen Sie, dass Sie ein Mensch sind.",
                    variant: AlertVariant.Warning,
                })
            );

            return;
        }

        const result = await KontaktService.send(formData);

        if (result) {
            // Success
            dispatch(
                alertAdded({
                    text: "Ihre Kontaktanfrage wurde gesendet. Vielen Dank.",
                    variant: AlertVariant.Success,
                })
            );

            if (formElement.current) {
                formElement.current.reset();
            }
            setFormData(initialFormDataValue);
        } else {
            // Not successful
            dispatch(
                alertAdded({
                    text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                    variant: AlertVariant.Danger,
                })
            );
        }
    };

    const setHcaptchaToken = (hcaptchaToken: string): void => {
        setFormData({
            ...formData,
            token: hcaptchaToken,
        });
    };

    const handleFormChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        setFormData({
            ...formData,
            [event.target.name]: xss(event.target.value),
        });
    };

    return (
        <>
            <div className="Kontakt mb-5">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <h1 className="text-center">Kontakt</h1>
                        <form ref={formElement} onSubmit={onFormSubmit}>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <Input
                                        id="user_first_name"
                                        type="text"
                                        placeholder="Vorname"
                                        required={true}
                                        onChange={handleFormChange}
                                    ></Input>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        id="user_last_name"
                                        type="text"
                                        placeholder="Nachname"
                                        required={true}
                                        onChange={handleFormChange}
                                    ></Input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        id="user_email"
                                        type="email"
                                        placeholder="E-Mail"
                                        required={true}
                                        onChange={handleFormChange}
                                    ></Input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <HorizontalLine></HorizontalLine>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        id="subject"
                                        type="text"
                                        placeholder="Betreff"
                                        required={true}
                                        onChange={handleFormChange}
                                    ></Input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Textarea
                                        id="content"
                                        rows={5}
                                        placeholder="Nachricht"
                                        required={true}
                                        onChange={handleFormChange}
                                    ></Textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="width-fit-content mx-auto my-2">
                                        <HCaptchaComponent
                                            setToken={setHcaptchaToken}
                                        ></HCaptchaComponent>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-0 col-xxl-2"></div>
                                <div className="col-12 col-sm-6 col-xxl-4">
                                    <IconButton
                                        title="Zurücksetzen"
                                        onClick={() => {}}
                                        type={ButtonType.Reset}
                                        Icon={TrashIcon}
                                        primary={false}
                                    ></IconButton>
                                </div>
                                <div className="col-12 col-sm-6 col-xxl-4">
                                    <IconButton
                                        title="Absenden"
                                        onClick={() => {}}
                                        type={ButtonType.Submit}
                                        Icon={PaperPlaneIcon}
                                        primary={true}
                                    ></IconButton>
                                </div>
                                <div className="col-0 col-xxl-2"></div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-lg-6 d-none d-lg-block">
                        <div className="Kontakt__image">
                            <EnvelopesImage
                                height="auto"
                                width="auto"
                            ></EnvelopesImage>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kontakt;
