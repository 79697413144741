import IconButton from "../buttons/iconButton/IconButton";
import SmallIconButton from "../buttons/smallIconButton/SmallIconButton";

import { ReactComponent as UndoIcon } from "./../../../assets/resources/icons/undo.svg";
import { ReactComponent as CrossIcon } from "./../../../assets/resources/icons/cross.svg";

export interface ConfirmationModalProps {
    title: string;
    content: string;
    setShow: Function;
    show: boolean;
    onCancel: Function;
    onConfirm: Function;
    actionBtnLabel: string;
    ActionBtnIcon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    > | null;
}

const ConfirmationModal = ({
    title,
    content,
    setShow,
    show,
    onCancel,
    onConfirm,
    actionBtnLabel,
    ActionBtnIcon,
}: ConfirmationModalProps) => {
    return (
        <>
            <div className="ConfirmationModal__bg">
                <div className="ConfirmationModal shadow">
                    <div className="row">
                        <div className="col-12">
                            <h2>{title}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="b1-sb">{content}</p>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-12 col-sm-6">
                            <IconButton
                                title="Abbrechen"
                                onClick={() => onCancel()}
                                Icon={UndoIcon}
                                primary={false}
                            ></IconButton>
                        </div>
                        <div className="my-2 d-sm-none"></div>
                        <div className="col-12 col-sm-6">
                            <IconButton
                                title={actionBtnLabel}
                                onClick={() => onConfirm()}
                                Icon={ActionBtnIcon}
                                primary={false}
                                danger={true}
                            ></IconButton>
                        </div>
                    </div>
                    <div className="ConfirmationModal__closeBtn">
                        <SmallIconButton
                            description="Bestätigungsfenster schließen"
                            onClick={() => onCancel()}
                            Icon={CrossIcon}
                        ></SmallIconButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmationModal;
