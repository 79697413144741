import VorstandCard from "./components/vorstandCard/VorstandCard";

import TEST from "./../../assets/resources/images/wappen.png";

const Vorstand = () => {
    return (
        <>
            <div className="mb-5">
                <h1 className="text-center">Vorstand</h1>
                <div className="row pt-5">
                    <div className="col-12 col-md-6 col-xxl-12 mb-6rem">
                        <VorstandCard
                            name="Juliane Staudte-Schuster"
                            position="Vorsitzende"
                            image={TEST}
                        ></VorstandCard>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-6rem mb-md-0">
                        <VorstandCard
                            name="Kora Glöckner"
                            position="stellvertretende Vorsitzende"
                            image={TEST}
                        ></VorstandCard>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4 mb-6rem mb-md-0">
                        <VorstandCard
                            name="Anja Grohme"
                            position="Schatzmeisterin"
                            image={TEST}
                        ></VorstandCard>
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <VorstandCard
                            name="Susen Großmann"
                            position="Schriftführerin"
                            image={TEST}
                        ></VorstandCard>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Vorstand;
