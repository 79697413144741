import classNames from "classnames";
import { useSlate } from "slate-react";
import { SlateCustomEditor } from "../../../../SlateCustomEditor";
import { SlateEditorFormatType } from "../../../../../../../enums/SlateEditorFormatType";

export interface SlateEditorToolbarBtnProps {
    isMark: boolean;
    format: SlateEditorFormatType;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    > | null;
}

const SlateEditorToolbarBtn = ({
    isMark,
    format,
    Icon,
}: SlateEditorToolbarBtnProps) => {
    const editor = useSlate();

    let active: boolean;
    if (isMark) {
        active = SlateCustomEditor.isMarkActive(editor, format);
    } else {
        active = SlateCustomEditor.isBlockActive(editor, format);
    }

    const onClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        
        if (isMark) {
            SlateCustomEditor.toggleMark(editor, format);
        } else {
            SlateCustomEditor.toggleBlock(editor, format);
        }
    };

    return (
        <>
            <button
                className={classNames("SlateEditorToolbarBtn", {
                    "SlateEditorToolbarBtn--active": active,
                })}
                onClick={onClick}
            >
                {Icon ? (
                    <Icon className="SlateEditorToolbarBtn__icon"></Icon>
                ) : (
                    <></>
                )}
            </button>
        </>
    );
};

export default SlateEditorToolbarBtn;
