import { Link } from "react-router-dom";
import INavigationPage from "../../../../interfaces/INavigationPage";

export interface MobileNavigationItemProps {
    item: INavigationPage;
    active: boolean;
    closeMobileNavigation: Function;
}

const MobileNavigationItem = ({
    item,
    active,
    closeMobileNavigation,
}: MobileNavigationItemProps) => {
    return (
        <>
            <li className="text-center">
                <Link
                    className={`MobileNavigationItem d-inline-block l2-sb ${
                        active ? "MobileNavigationItem--active" : ""
                    }`}
                    to={item.route}
                    onClick={() => closeMobileNavigation()}
                >
                    {item.name}
                </Link>
            </li>
        </>
    );
};

export default MobileNavigationItem;
