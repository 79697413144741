import { ReactComponent as CancelIcon } from "../../../assets/resources/icons/cancel.svg";

const Error403 = () => {
    return (
        <>
            <div className="Error403">
                <div className="Error403__content">
                    <CancelIcon className="Error__403__icon"></CancelIcon>

                    <h1>Error 403 - Zugriff verweigert</h1>
                </div>
            </div>
        </>
    );
};

export default Error403;
