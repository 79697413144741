import { Alert } from "react-bootstrap";
import IAlert from "../../../../../interfaces/IAlert";

export interface CustomAlertProps {
    alert: IAlert;
    onClose: (alert: IAlert) => void;
}

const CustomAlert = ({ alert, onClose }: CustomAlertProps) => {
    return (
        <>
            <Alert
                className="shadow"
                variant={alert.variant}
                onClose={() => onClose(alert)}
                dismissible
            >
                {alert.text}
            </Alert>
        </>
    );
};

export default CustomAlert;
