import { useState } from "react";

import AufnahmeformularService from "../../services/aufnahmeformularService/AufnahmeformularService";

import VerticalLine from "../../components/common/verticalLine/VerticalLine";
import IconButton from "../../components/common/buttons/iconButton/IconButton";
import BeitrittUploadSide from "./components/beitrittUploadSide/BeitrittUploadSide";

import { ReactComponent as DownloadIcon } from "./../../assets/resources/icons/download.svg";
import { useAppDispatch } from "../../hooks";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { AlertVariant } from "../../enums/AlertVariant";

const Beitritt = () => {
    const dispatch = useAppDispatch();
    
    const [files, setFiles] = useState<(File & { preview?: string })[]>([]);
    const [successfulSendData, setSuccessfulSendData] = useState<
        boolean | null
    >(null);
    const [hcaptchaToken, setHcaptchaToken] = useState<string | null>(null);

    const onDownloadFormClick = () => {
        window.open("/documents/Antrag-auf-Mitgliedschaft.pdf", "_blank");
    };

    const onSubmitBtnClick = async () => {
        if (hcaptchaToken === null || hcaptchaToken === "") {
            console.warn("Not completed Captcha!");

            dispatch(
                alertAdded({
                    text: "Bitte bestätigen Sie, dass Sie ein Mensch sind.",
                    variant: AlertVariant.Warning,
                })
            );

            return;
        }

        if (files.length === 0) {
            dispatch(
                alertAdded({
                    text: "Es wurden keine Datei hochgeladen.",
                    variant: AlertVariant.Warning,
                })
            );

            return;
        }

        const result = await AufnahmeformularService.send(
            files[0],
            hcaptchaToken
        );

        // Delete files
        files.forEach((file) => {
            if (file.preview) {
                URL.revokeObjectURL(file.preview);
            }
        });
        setFiles([]);

        setSuccessfulSendData(result);
    };

    return (
        <>
            <div className="Beitritt mb-5">
                <h1 className="text-center">Beitritt</h1>
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <div className="Beitritt_leftSide">
                            <h2 className="text-center">Aufnahmeformular</h2>
                            <p className="">
                                Sie möchten dem Förderverein beitreten?
                                <br />
                                Klicken sie bitte auf den nachfolgenden Button,
                                um das Formular herunterzuladen.
                            </p>
                            <p>
                                Das ausgefüllte Formular kann auf der rechten
                                Seite hochgeladen und abgesendet werden.
                                Alternativ können Sie es auch per E-Mail senden.
                            </p>

                            <div className="width-fit-content mx-auto mt-5">
                                <IconButton
                                    title="Formular herunterladen"
                                    onClick={onDownloadFormClick}
                                    Icon={DownloadIcon}
                                    primary={true}
                                    padding={true}
                                ></IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex col-lg-2 justify-content-center">
                        <VerticalLine dark={true}></VerticalLine>
                    </div>
                    <div className="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center">
                        <BeitrittUploadSide
                            successfulSendData={successfulSendData}
                            files={files}
                            setFiles={setFiles}
                            onSubmitBtnClick={onSubmitBtnClick}
                            setHcaptchaToken={setHcaptchaToken}
                        ></BeitrittUploadSide>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Beitritt;
