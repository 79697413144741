export interface VerticalLineProps {
    dark: boolean;
}

const VerticalLine = ({ dark }: VerticalLineProps) => {
    return (
        <>
            <div
                className={`VerticalLine ${dark ? "VerticalLine--dark" : ""}`}
            ></div>
        </>
    );
};

export default VerticalLine;
