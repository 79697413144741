import { initialUserState, setUser } from "../../features/user/userSlice";
import { AppDispatch } from "../../store";
import RequestService from "../requestService/RequestService";
import { RequestMethod } from "../../enums/RequestMethod";
import IUser from "./../../interfaces/IUser";
import { IUploadUser } from "../../interfaces/upload/IUploadUser";

const UserService = {
    initialize: async (dispatch: AppDispatch) => {
        try {
            const response = await RequestService.makeRequest<IUser>(
                "/api/logged-in-user",
                RequestMethod.GET
            );
            if (
                "error" in response.data ||
                response.data.id === undefined ||
                response.data.id === null
            ) {
                // User not logged in
                dispatch(setUser(initialUserState));
            } else {
                // User is logged in
                dispatch(setUser(response.data));
            }
        } catch (err) {
            console.error(err);
        }
    },

    login: async (
        username: string,
        password: string,
        token: string,
        dispatch: AppDispatch
    ): Promise<boolean> => {
        let loginSuccess: boolean = false;

        try {
            const response = await RequestService.makeRequest<IUser>(
                "/api/login",
                RequestMethod.POST,
                {
                    username,
                    password,
                    token,
                }
            );

            if (
                "error" in response.data ||
                response.data.id === undefined ||
                response.data.id === null
            ) {
                // User not logged in
                dispatch(setUser(initialUserState));
            } else {
                // User is logged in
                dispatch(setUser(response.data));
                loginSuccess = true;
            }
        } catch (err) {
            console.error(err);
        }

        return loginSuccess;
    },

    logout: async (dispatch: AppDispatch): Promise<boolean> => {
        let success: boolean = false;

        try {
            const response = await RequestService.makeRequest<{
                success: boolean;
            }>("/api/logout", RequestMethod.GET);

            if ("error" in response.data || !response.data.success) {
                throw new Error("Nutzer konnte nicht ausgeloggt werden.");
            } else {
                dispatch(setUser(initialUserState));
                success = true;
            }
        } catch (err) {
            console.error(err);
        }

        return success;
    },

    getAllUsers: async (): Promise<IUser[]> => {
        try {
            const response = await RequestService.makeRequest<IUser[]>(
                "/api/users",
                RequestMethod.GET
            );

            if ("error" in response.data) {
                throw new Error("Nutzeraccounts konnten nicht geladen werden.");
            } else {
                return response.data;
            }
        } catch (err) {
            console.error(err);
            return [];
        }
    },

    createUser: async (data: IUploadUser): Promise<boolean> => {
        let success: boolean = false;

        try {
            const requestResponse = await RequestService.makeRequest<{
                success: Boolean;
                id: Number;
            }>("/api/create-new-user", RequestMethod.POST, data);

            if (
                "error" in requestResponse.data ||
                requestResponse.data.success === false
            ) {
                throw new Error("Nutzer konnte nicht hinzugefügt werden.");
            }

            success = true;
        } catch (err) {
            console.log(err);
        }

        return success;
    },

    modifyUser: async (): Promise<void> => {},

    deleteUser: async (id: number): Promise<boolean> => {
        let success: boolean = false;

        try {
            const body = {
                id,
            };
            const response = await RequestService.makeRequest<{
                success: Boolean;
            }>("/api/delete/user", RequestMethod.DELETE, body);

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }
        } catch (err) {
            console.error(err);
        }

        return success;
    },
};

export default UserService;
