import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { userIsLoggedIn } from "../../../../features/user/userSlice";
import KontaktService from "../../../../services/kontaktService/KontaktService";
import SmallIconButton from "../../../../components/common/buttons/smallIconButton/SmallIconButton";

import { ReactComponent as TrashIcon } from "../../../../assets/resources/icons/trash.svg";
import ConfirmationModal from "../../../../components/common/confirmationModal/ConfirmationModal";

const AdminDashboardKontaktanfragen = () => {
    const dispatch = useAppDispatch();

    const isLoggedIn = useAppSelector(userIsLoggedIn);
    const kontaktanfragen = useAppSelector(
        (state) => state.admin.kontaktanfragen
    );

    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<number | null>(null);

    const onDeleteBtnClick = (id: number): void => {
        setIdToDelete(id);
        setShowConfirmationModal(true);
    };

    const onConfirmDeleteClick = async (): Promise<void> => {
        setShowConfirmationModal(false);

        if (idToDelete !== null && idToDelete >= 0) {
            const result = await KontaktService.delete(idToDelete);
            if (result) {
                KontaktService.load(dispatch);
            }
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            KontaktService.load(dispatch);
        }
    }, [dispatch, isLoggedIn]);

    return (
        <>
            <div className="AdminDashboardKontaktanfragen mb-5">
                <h2>Kontaktanfragen</h2>
                {kontaktanfragen.length === 0 ? (
                    <i>Keine Formulare vorhanden</i>
                ) : (
                    <></>
                )}
                <div>
                    <Accordion>
                        {kontaktanfragen.map((anfrage, index) => (
                            <Accordion.Item
                                key={`key-kontaktanfrage-${index}`}
                                eventKey={index + ""}
                            >
                                <Accordion.Header>
                                    {anfrage.subject}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        <strong>Absender:</strong>{" "}
                                        {`${anfrage.user_last_name}, ${anfrage.user_first_name}`}
                                    </p>
                                    <p>
                                        <strong>E-Mail:</strong>{" "}
                                        {anfrage.user_email}
                                    </p>
                                    <p>
                                        <strong>Datum:</strong>{" "}
                                        {new Date(
                                            anfrage.timestamp * 1000
                                        ).toLocaleString()}
                                    </p>
                                    <p>
                                        <strong>Betreff:</strong>{" "}
                                        {anfrage.subject}
                                    </p>
                                    <p>
                                        <strong>Inhalt:</strong>{" "}
                                        {anfrage.content}
                                    </p>
                                    <SmallIconButton
                                        description={"Löschen"}
                                        onClick={() =>
                                            onDeleteBtnClick(anfrage.id)
                                        }
                                        Icon={TrashIcon}
                                        danger={true}
                                    ></SmallIconButton>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
                {showConfirmationModal ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie die Anfrage wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowConfirmationModal}
                        show={showConfirmationModal}
                        onCancel={() => setShowConfirmationModal(false)}
                        onConfirm={onConfirmDeleteClick}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default AdminDashboardKontaktanfragen;
