import { useEffect, useState } from "react";
import DropzoneComponent from "../../../../common/forms/dropzoneComponent/DropzoneComponent";
import { ReactComponent as PDFIcon } from "../../../../../assets/resources/icons/pdf-file.svg";
import IconButton from "../../../../common/buttons/iconButton/IconButton";

export interface EditDocumentDropzoneProps {
    setDocument: Function;
}

const EditDocumentDropzone = ({ setDocument }: EditDocumentDropzoneProps) => {
    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        // Move document to parent component
        if (files.length > 0) {
            setDocument(files[0]);
        }
    }, [files]);

    return (
        <>
            <DropzoneComponent
                accept={{ "application/pdf": [] }}
                multiple={false}
                files={files}
                setFiles={setFiles}
                preview={true}
            >
                <div className="w-100 h-100 d-flex justify-content-center align-items-center py-4">
                    <div className="w-50">
                        <PDFIcon
                            className="ic-b700 d-block mx-auto"
                            height="3rem"
                            width="auto"
                        ></PDFIcon>
                        <p className="b1-sb text-center mt-3">
                            Dokument hierher ziehen zum hochladen
                        </p>
                        <div className="d-flex align-items-center my-4">
                            <div className="custom-hr"></div>
                            <span className="tc-n300 mx-3">ODER</span>
                            <div className="custom-hr"></div>
                        </div>
                        <IconButton
                            title="Dateien durchsuchen"
                            onClick={() => {}}
                            Icon={null}
                            primary={true}
                        ></IconButton>
                    </div>
                </div>
            </DropzoneComponent>
        </>
    );
};

export default EditDocumentDropzone;
