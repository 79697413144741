import { Link } from "react-router-dom";

export interface NavigationItemProps {
    name: string;
    to: string;
    active: boolean;
}

const NavigationItem = ({ name, to, active }: NavigationItemProps) => {
    return (
        <>
            <li className="d-inline-block">
                <Link
                    className={`NavigationItem d-inline-block l2-sb py-2 mx-3 ${
                        active ? "NavigationItem--active" : ""
                    }`}
                    to={to}
                >
                    {name}
                </Link>
            </li>
        </>
    );
};

export default NavigationItem;
