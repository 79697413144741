export interface VorstandCardProps {
    name: string;
    position: string;
    image: string;
}

const VorstandCard = ({ name, position, image }: VorstandCardProps) => {
    return (
        <>
            <div className="VorstandCard shadow">
                <div className="VorstandCard__image">
                    <img src={image} alt={`Bild von ${name}`} />
                </div>
                <h3 className="text-center">{name}</h3>
                <span className="l0-sb text-center">{position}</span>
            </div>
        </>
    );
};

export default VorstandCard;
