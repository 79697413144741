import { Descendant, createEditor } from "slate";
import { Slate, withReact } from "slate-react";
import SlateEditable from "./components/slateEditable/SlateEditable";
import { useState } from "react";
import SlateEditorToolbar from "./components/slateEditorToolbar/SlateEditorToolbar";
import SlateEditorUploadImageModal from "./components/slateEditorUploadImageModal/SlateEditorUploadImageModal";
import { SlateCustomEditor } from "./SlateCustomEditor";
import { CustomElement } from "./SlateEditorTypes";
import CommonService from "../../../services/commonService/CommonService";

export interface SlateEditorProps {
    readOnly: boolean;
    initialValue: Descendant[];
    saveValue?: (value: Descendant[]) => void;
}

const SlateEditor = ({
    readOnly,
    initialValue,
    saveValue,
}: SlateEditorProps) => {
    const [editor] = useState(() => withReact(createEditor()));
    const [showUploadImageModal, setShowUploadImageModal] =
        useState<boolean>(false);

    const onChange = (value: Descendant[]): void => {
        const isAstChange = editor.operations.some(
            (op) => "set_selection" !== op.type
        );

        if (!isAstChange) return;

        // Save the value
        if (saveValue) saveValue(value);
    };

    const onImageUploaded = (
        url: string,
        alt: string,
        heading?: string | undefined
    ): void => {
        SlateCustomEditor.insertImage(editor, url, alt, heading ?? "");
    };

    const onImageDeleted = async (image: CustomElement): Promise<void> => {
        if (!image.imgUrl) {
            throw Error("imgUrl property is missing")
        }

        const result = await CommonService.deleteUploadArticleImage(image.imgUrl);

        if (result === false) {
            throw Error("Bild konnte nicht gelöscht werden!");
        }

        SlateCustomEditor.deleteImage(editor, image);
    };

    return (
        <>
            <div className="position-relative">
                <Slate
                    editor={editor}
                    initialValue={initialValue}
                    onChange={onChange}
                >
                    {!readOnly ? (
                        <SlateEditorToolbar
                            setShowUploadImageModal={setShowUploadImageModal}
                        ></SlateEditorToolbar>
                    ) : (
                        <></>
                    )}

                    <SlateEditable
                        readOnly={readOnly}
                        onImageDeleted={onImageDeleted}
                    ></SlateEditable>
                </Slate>
                {showUploadImageModal ? (
                    <SlateEditorUploadImageModal
                        setShow={setShowUploadImageModal}
                        uploadedImage={onImageUploaded}
                    ></SlateEditorUploadImageModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default SlateEditor;
