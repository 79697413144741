import { DatabaseDataService } from "../databaseDataService/DatabaseDataService";
import CommonService from "../commonService/CommonService";
import RequestService from "../requestService/RequestService";
import { RequestMethod } from "../../enums/RequestMethod";

import IArticle from "../../interfaces/IArticle";
import { IUploadProjekt } from "../../interfaces/upload/IUploadProjekt";

export class ProjekteService extends DatabaseDataService {
    protected static urlName = "projekte";
    protected static friendlyName = "Projekt";
    protected static friendlyNamePlural = "Projekte";

    public static async get(): Promise<IArticle[]> {
        return this._get<IArticle>();
    }

    public static async add(
        data: IUploadProjekt,
        author: string
    ): Promise<boolean> {
        let success: boolean = false;

        try {
            const titleImage = data.galeryImages[0];

            const titleImagePath = await CommonService.uploadArticleImage(
                titleImage.image
            );

            // Send information
            const projektRequestBody = {
                title: data.title,
                preview_text: data.previewText,
                author: author,
                content: JSON.stringify(data.content),
                title_image: titleImagePath,
                title_image_alt: titleImage.image_alt,
                title_image_subtitle: titleImage.image_subtitle,
                draft: data.draft,
            };
            const projektRequestResponse = await RequestService.makeRequest<{
                success: Boolean;
                id: number;
            }>(
                `/api/add/${this.urlName}`,
                RequestMethod.POST,
                projektRequestBody
            );

            if (
                "error" in projektRequestResponse.data ||
                projektRequestResponse.data.success === false
            ) {
                throw new Error(
                    `${this.friendlyName} konnte nicht hinzugefügt werden.`
                );
            }

            const projektId = projektRequestResponse.data.id;

            // Upload galery images
            data.galeryImages.forEach(async (image) => {
                const imageRequestFormData = new FormData();
                imageRequestFormData.append("image_alt", image.image_alt);
                imageRequestFormData.append(
                    "image_subtitle",
                    image.image_subtitle
                );
                imageRequestFormData.append("article_id", projektId + "");
                imageRequestFormData.append("type", "article-image");
                imageRequestFormData.append("image", image.image);

                const imageRequestResponse = await RequestService.makeRequest<{
                    success: Boolean;
                }>(
                    "/api/add/projekt-galeriebild",
                    RequestMethod.POST,
                    undefined,
                    imageRequestFormData,
                    {}
                );

                if (
                    "error" in imageRequestResponse.data ||
                    imageRequestResponse.data.success === false
                ) {
                    throw new Error(
                        "Galeriebild konnte nicht hinzugefügt werden."
                    );
                }
            });

            success = true;
        } catch (err) {
            console.error(err);
        }

        return success;
    }

    public static async publish(id: number): Promise<boolean> {
        return this._publish(id);
    }

    public static async delete(id: number): Promise<boolean> {
        return this._delete(id);
    }
}
