import { useEffect, useState } from "react";
import {
    userIsAdmin,
    userIsLoggedIn,
} from "../../../../features/user/userSlice";
import { useAppSelector } from "../../../../hooks";
import UserService from "../../../../services/userService/UserService";
import IUser from "../../../../interfaces/IUser";
import { Accordion } from "react-bootstrap";
import SmallIconButton from "../../../../components/common/buttons/smallIconButton/SmallIconButton";

import { ReactComponent as TrashIcon } from "../../../../assets/resources/icons/trash.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/resources/icons/plus.svg";
import ConfirmationModal from "../../../../components/common/confirmationModal/ConfirmationModal";
import IconButton from "../../../../components/common/buttons/iconButton/IconButton";
import { useNavigate } from "react-router-dom";

const AdminDashboardUserManagement = () => {
    const navigate = useNavigate();

    const isLoggedIn = useAppSelector(userIsLoggedIn);
    const isAdmin = useAppSelector(userIsAdmin);

    const [users, setUsers] = useState<IUser[]>([]);

    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<number | null>(null);

    const loadUserAccounts = async (): Promise<void> => {
        if (!isLoggedIn && !isAdmin) {
            setUsers([]);
            return;
        }

        const userAccounts = await UserService.getAllUsers();
        setUsers(userAccounts);
    };

    const onDeleteBtnClick = (id: number): void => {
        setIdToDelete(id);
        setShowConfirmationModal(true);
    };

    const onConfirmDeleteClick = async (): Promise<void> => {
        setShowConfirmationModal(false);

        if (idToDelete !== null && idToDelete >= 0) {
            const result = await UserService.deleteUser(idToDelete);
            if (result) {
                loadUserAccounts();
            }
        }
    };

    const onCreateNewUserBtnClick = (
        e: React.MouseEvent<HTMLButtonElement>
    ): void => {
        e.preventDefault();
        e.stopPropagation();

        navigate("/admin/add/user");
    };

    useEffect(() => {
        loadUserAccounts();
    }, [isLoggedIn, isAdmin]);

    // TODO ?
    if (!isLoggedIn || !isAdmin) {
        return <strong>Sie haben nicht die benötigten Rechte</strong>;
    }

    return (
        <>
            <div className="AdminDashboardUserManagement mb-5">
                <h2>Benutzerverwaltung</h2>
                <div className="mb-3">
                    <IconButton
                        title="Neuen Benutzer erstellen"
                        onClick={onCreateNewUserBtnClick}
                        Icon={PlusIcon}
                        primary={true}
                    ></IconButton>
                </div>
                {users.length === 0 ? (
                    <i>Keine Nutzeraccounts vorhanden</i>
                ) : (
                    <></>
                )}
                <div>
                    <Accordion>
                        {users.map((user, index) => (
                            <Accordion.Item
                                key={`key-usermanagement-${index}`}
                                eventKey={index + ""}
                            >
                                <Accordion.Header>
                                    {user.username}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        <strong>E-Mail:</strong> {user.email}
                                    </p>
                                    <p>
                                        <strong>Letzter Login:</strong>{" "}
                                        {new Date(
                                            user.last_login * 1000
                                        ).toLocaleString()}
                                    </p>
                                    <p>
                                        <strong>Admin-Rechte:</strong>{" "}
                                        {user.is_admin === 1 ? "JA" : "NEIN"}
                                    </p>
                                    <p>
                                        <strong>
                                            Ausschließlich Leserechte:
                                        </strong>{" "}
                                        {user.only_read === 1 ? "JA" : "NEIN"}
                                    </p>
                                    <SmallIconButton
                                        description={"Löschen"}
                                        onClick={() =>
                                            onDeleteBtnClick(user.id)
                                        }
                                        Icon={TrashIcon}
                                        danger={true}
                                    ></SmallIconButton>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
                {showConfirmationModal ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie den Nutzer wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowConfirmationModal}
                        show={showConfirmationModal}
                        onCancel={() => setShowConfirmationModal(false)}
                        onConfirm={onConfirmDeleteClick}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default AdminDashboardUserManagement;
