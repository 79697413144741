import { useEffect, useState } from "react";

import { ReactComponent as ImageIcon } from "../../../../../assets/resources/icons/image.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/resources/icons/cross.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/resources/icons/upload.svg";

import Input from "../../../../common/forms/input/Input";
import IconButton from "../../../../common/buttons/iconButton/IconButton";
import SmallIconButton from "../../../../common/buttons/smallIconButton/SmallIconButton";
import { ButtonType } from "../../../../../enums/ButtonType";
import DropzoneComponent from "../../../../common/forms/dropzoneComponent/DropzoneComponent";
import CommonService from "../../../../../services/commonService/CommonService";

export interface SlateEditorUploadImageModalProps {
    setShow: Function;
    uploadedImage: (url: string, alt: string, heading?: string) => void;
}

const SlateEditorUploadImageModal = ({
    setShow,
    uploadedImage,
}: SlateEditorUploadImageModalProps) => {
    const [files, setFiles] = useState<(File & { preview?: string })[]>([]);
    const [alt, setAlt] = useState<string>("");
    const [imgHeading, setImgHeading] = useState<string>("");

    const onCloseBtnClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        // TODO: Close Modal
        //!!!!!
        setFiles([]);
        setShow(false);
    };

    const onSubmit = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        // TODO: Show warning
        if (files.length === 0 || alt === "") return;

        const filepath = await CommonService.uploadArticleImage(files[0]);

        if (filepath) {
            if (files[0].preview) {
                URL.revokeObjectURL(files[0].preview);
            }

            setFiles([]);
            uploadedImage(filepath, alt, imgHeading ?? undefined);
            setShow(false);
        } else {
            // TODO: Error handling
        }
    };

    useEffect(() => {
        console.log(files);
    }, [files]);

    return (
        <>
            <div className="SlateEditorUploadImageModalContainer rounded">
                <div className="SlateEditorUploadImageModal position-relative shadow animate__animated animate__fadeInUp">
                    <div className="row">
                        <div className="col-6">
                            <DropzoneComponent
                                accept={{
                                    "image/*": [],
                                }}
                                multiple={false}
                                files={files}
                                setFiles={setFiles}
                                preview={true}
                            >
                                <div className="w-100 h-100 d-flex justify-content-center align-items-center py-4">
                                    <div className="w-50">
                                        <ImageIcon
                                            className="ic-b700 d-block mx-auto"
                                            height="3rem"
                                            width="auto"
                                        ></ImageIcon>
                                        <p className="b1-sb text-center mt-3">
                                            Bild hierher ziehen zum hochladen
                                        </p>
                                    </div>
                                </div>
                            </DropzoneComponent>
                        </div>
                        <div className="col-6 d-flex flex-column justify-content-between">
                            <Input
                                id="input-add-image-alt"
                                type="text"
                                placeholder="Alternativtext"
                                required={true}
                                onChange={(e) => setAlt(e.target.value)}
                            ></Input>
                            <Input
                                id="input-add-image-heading"
                                type="text"
                                placeholder="Bildunterschrift (Optional)"
                                required={false}
                                onChange={(e) => setImgHeading(e.target.value)}
                            ></Input>
                            <IconButton
                                title="Hochladen"
                                onClick={onSubmit}
                                Icon={UploadIcon}
                                primary={true}
                                type={ButtonType.Submit}
                            ></IconButton>
                        </div>
                        <div className="SlateEditorUploadImageModal__close">
                            <SmallIconButton
                                description="Dialog schließen"
                                onClick={onCloseBtnClick}
                                Icon={CrossIcon}
                                small={true}
                            ></SmallIconButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SlateEditorUploadImageModal;
