import { Outlet, useNavigate } from "react-router-dom";
import { userIsLoggedIn } from "../../features/user/userSlice";
import { useAppSelector } from "../../hooks";
import AdminNavBar from "./components/adminNavBar/AdminNavBar";
import { useEffect } from "react";

const AdminDashboard = () => {
    const navigate = useNavigate();
    const isLoggedIn = useAppSelector(userIsLoggedIn);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/admin");
            }
        }, 400);

        return () => clearTimeout(timer);
    }, [isLoggedIn]);

    return (
        <>
            <div className="AdminDashboard">
                <div className="row">
                    <div className="col-2">
                        <AdminNavBar></AdminNavBar>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-9">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminDashboard;
