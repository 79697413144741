import { EditPageOptionDataFieldType } from "../../../enums/EditPageOptionDataFieldType";
import { IEditPageOptionDataField } from "../../../interfaces/IEditPageOptionDataField";
import EditPageOptions from "./EditPageOptions";
import Input from "../../common/forms/input/Input";
import Textarea from "../../common/forms/textarea/Textarea";
import EditDocumentDropzone from "./components/editDocumentDropzone/EditDocumentDropzone";
import EditImageDropzone from "./components/editImageDropzone/EditImageDropzone";
import EditImagePreview from "./components/editImagePreview/EditImagePreview";
import { useEffect, useState } from "react";
import IArticleImageAdd from "../../../interfaces/IArticleImageAdd";
import { Descendant } from "slate";
import { IUploadProjekt } from "../../../interfaces/upload/IUploadProjekt";
import SlateEditor from "../../common/slateEditor/SlateEditor";
import { SlateEditorFormatType } from "../../../enums/SlateEditorFormatType";
import { IUploadAnkuendigung } from "../../../interfaces/upload/IUploadAnkuendigung";
import { IUploadEvent } from "../../../interfaces/upload/IUploadEvent";
import { IUploadSatzung } from "../../../interfaces/upload/IUploadSatzung";
import { IUploadUser } from "../../../interfaces/upload/IUploadUser";

export interface EditPagePropertyMappingProps {
    selectedIndex: number;
    data:
        | null
        | IUploadProjekt
        | IUploadAnkuendigung
        | IUploadEvent
        | IUploadSatzung
        | IUploadUser;
    setData: Function;
}

const EditPagePropertyMapping = ({
    selectedIndex,
    data,
    setData,
}: EditPagePropertyMappingProps) => {
    const configuration = EditPageOptions[selectedIndex];

    const [galeryImages, setGaleryImages] = useState<IArticleImageAdd[]>([]);
    const [document, setDocument] = useState<File | null>(null);

    const updateValue = (dataFieldName: string, value: any): void => {
        const prop = dataFieldName as keyof typeof data;

        if (data && data[prop] !== undefined) {
            setData({ ...data, [prop]: value });
        }
    };

    const updateEditTextBoxValue =
        (dataFieldName: string) =>
        (value: Descendant[]): void => {
            updateValue(dataFieldName, value);
        };

    const dataFieldMapping = (dataField: IEditPageOptionDataField) => {
        const required = dataField.optional === true ? false : true;

        switch (dataField.type) {
            case EditPageOptionDataFieldType.Number:
                // TODO: disabled option
                return (
                    <Input
                        id={`add-post-${configuration.name}-${dataField.name}`}
                        type="number"
                        placeholder={dataField.friendlyName}
                        required={required}
                        disabled={dataField.disabled}
                        onChange={(e) =>
                            updateValue(
                                dataField.name,
                                parseInt(e.target.value)
                            )
                        }
                    ></Input>
                );
            case EditPageOptionDataFieldType.String:
                return (
                    <Input
                        id={`add-post-${configuration.name}-${dataField.name}`}
                        type="text"
                        placeholder={dataField.friendlyName}
                        required={required}
                        disabled={dataField.disabled}
                        onChange={(e) =>
                            updateValue(dataField.name, e.target.value)
                        }
                    ></Input>
                );
            case EditPageOptionDataFieldType.Password:
                return (
                    <Input
                        id={`add-post-${configuration.name}-${dataField.name}`}
                        type="password"
                        placeholder={dataField.friendlyName}
                        required={required}
                        disabled={dataField.disabled}
                        onChange={(e) =>
                            updateValue(dataField.name, e.target.value)
                        }
                    ></Input>
                );
            case EditPageOptionDataFieldType.Text:
                return (
                    <Textarea
                        id={`add-post-${configuration.name}-${dataField.name}`}
                        rows={3}
                        placeholder={dataField.friendlyName}
                        required={required}
                        onChange={(e) =>
                            updateValue(dataField.name, e.target.value)
                        }
                    ></Textarea>
                );
            case EditPageOptionDataFieldType.RichText:
                return (
                    <div className="mb-3">
                        <SlateEditor
                            readOnly={false}
                            initialValue={[
                                {
                                    type: SlateEditorFormatType.Paragraph,
                                    children: [{ text: "Inhalt" }],
                                },
                            ]}
                            saveValue={updateEditTextBoxValue(dataField.name)}
                        ></SlateEditor>
                    </div>
                );
            case EditPageOptionDataFieldType.Date:
                return (
                    <Input
                        id={`add-post-${configuration.name}-${dataField.name}`}
                        type="date"
                        placeholder={dataField.friendlyName}
                        required={required}
                        disabled={dataField.disabled}
                        onChange={(e) =>
                            updateValue(
                                dataField.name,
                                Math.round(
                                    new Date(e.target.value).getTime() / 1000
                                )
                            )
                        }
                    ></Input>
                );
            case EditPageOptionDataFieldType.ImageDropzone:
                return (
                    <div className="mb-3">
                        <EditImageDropzone
                            images={galeryImages}
                            setImages={setGaleryImages}
                        ></EditImageDropzone>
                    </div>
                );
            case EditPageOptionDataFieldType.ImagePreview:
                return galeryImages.length > 0 ? (
                    <div className="mb-3">
                        <EditImagePreview
                            images={galeryImages}
                            setImages={setGaleryImages}
                        ></EditImagePreview>
                    </div>
                ) : (
                    <></>
                );
            case EditPageOptionDataFieldType.DocumentDropzone:
                return (
                    <div className="mb-3">
                        <EditDocumentDropzone
                            setDocument={setDocument}
                        ></EditDocumentDropzone>
                    </div>
                );
            case EditPageOptionDataFieldType.Checkbox:
                return (
                    <>
                        <div className="mb-3">
                            <input
                                className="me-3"
                                type="checkbox"
                                id={`add-post-${configuration.name}-${dataField.name}`}
                                name={dataField.name}
                                value={dataField.friendlyName}
                                onChange={(e) =>
                                    updateValue(
                                        dataField.name,
                                        e.target.checked
                                    )
                                }
                            ></input>
                            <label
                                htmlFor={`add-post-${configuration.name}-${dataField.name}`}
                            >
                                {dataField.friendlyName}
                            </label>
                        </div>
                    </>
                );
        }
    };

    useEffect(() => {
        if (data && data.hasOwnProperty("galeryImages") === false) return;

        updateValue("galeryImages", galeryImages);
    }, [galeryImages]);

    useEffect(() => {
        if (data && data.hasOwnProperty("document") === false) return;

        updateValue("document", document);
    }, [document]);

    return (
        <>
            {configuration.dataFields.map((dataField) => (
                <div
                    key={`key-datafield-row-${dataField.name}`}
                    className="row"
                >
                    <div className="col-12">{dataFieldMapping(dataField)}</div>
                </div>
            ))}
        </>
    );
};

export default EditPagePropertyMapping;
