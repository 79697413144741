import Slider from "react-slick";

import IEvent from "../../../../interfaces/IEvent";
import StartseiteNextEventCard from "./components/StartseiteNextEventCard/StartseiteNextEventCard";
import { useSelector } from "react-redux";
import { userCanEditContent } from "../../../../features/user/userSlice";

export interface StartseiteNextEventsSliderProps {
    events: IEvent[];
    publishEvent: (id: number) => void;
    deleteEvent: (id: number) => void;
    openEventModal: (event: IEvent) => void;
}

const StartseiteNextEventsSlider = ({
    events,
    publishEvent,
    deleteEvent,
    openEventModal,
}: StartseiteNextEventsSliderProps) => {
    const userCanEdit = useSelector(userCanEditContent);

    const settings = {
        dots: false,
        infinite: false,
        speed: 400,
        initialSlide: 0,
        swipeToSlide: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: true,
    };

    return (
        <>
            <div className="StartseiteNextEventsSlider">
                <Slider
                    className="StartseiteNextEventsSlider_slider"
                    {...settings}
                >
                    {events.map((event, index) => {
                        if (userCanEdit === false && event.draft === 1) {
                            return <></>;
                        }

                        return (
                            <StartseiteNextEventCard
                                key={`key-next-event-${index}`}
                                event={event}
                                publishEvent={publishEvent}
                                deleteEvent={deleteEvent}
                                openEventModal={openEventModal}
                            ></StartseiteNextEventCard>
                        );
                    })}
                </Slider>
            </div>
        </>
    );
};

export default StartseiteNextEventsSlider;
