import { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import SmallIconButton from "../../buttons/smallIconButton/SmallIconButton";

import { ReactComponent as CrossIcon } from "./../../../../assets/resources/icons/cross.svg";
import { ReactComponent as TextFileIcon } from "./../../../../assets/resources/icons/text-file.svg";
import { ReactComponent as PDFFileIcon } from "./../../../../assets/resources/icons/pdf-file.svg";

export interface DropzoneComponentProps {
    children: React.ReactNode;
    accept: {};
    multiple: boolean;
    files: (File & { preview?: string })[];
    setFiles: Function;
    preview?: boolean;
}

export const getPreviewImage = (file: File & { preview?: string }) => {
    if (file.preview && file.preview !== undefined) {
        return <img src={file.preview} alt={file.name} />;
    }

    switch (file.type) {
        case "application/pdf":
            return <PDFFileIcon height="100%" width="auto"></PDFFileIcon>;
        default:
            return <TextFileIcon height="100%" width="auto"></TextFileIcon>;
    }
};

const DropzoneComponent = ({
    children,
    accept,
    multiple,
    files,
    setFiles,
    preview = true,
}: DropzoneComponentProps) => {
    const createPreviewUrl = (file: File): File & { preview?: string } => {
        if (file.type.substring(0, 5) === "image") {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
        } else {
            return file;
        }
    };

    const onDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map((file: File) =>
            createPreviewUrl(file)
        );
        const newValue = multiple ? files.concat(newFiles) : newFiles;
        setFiles(newValue);
    };

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject,
    } = useDropzone({
        accept,
        onDrop,
        multiple,
    });

    const previewElements = (
        <div className="DropzoneComponent__preview">
            {files.map((file) => (
                <div
                    key={file.name}
                    className="DropzoneComponent__preview-image"
                >
                    {getPreviewImage(file)}

                    <div className="DropzoneComponent__preview__cross">
                        <SmallIconButton
                            description="Datei entfernen"
                            onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                event.preventDefault();
                                event.stopPropagation();

                                if (file.preview) {
                                    URL.revokeObjectURL(file.preview);
                                }

                                setFiles(files.filter((el) => el !== file));
                            }}
                            Icon={CrossIcon}
                            small={true}
                            danger={true}
                        ></SmallIconButton>
                    </div>
                </div>
            ))}
        </div>
    );

    const className = useMemo(() => {
        return `DropzoneComponent ${
            isDragAccept ? "DropzoneComponent--accept" : ""
        } ${isDragActive ? "DropzoneComponent--active" : ""} ${
            isDragReject ? "DropzoneComponent--reject" : ""
        }`;
    }, [isDragAccept, isDragActive, isDragReject]);

    return (
        <>
            <div {...getRootProps({ className })}>
                <input {...getInputProps()} />
                <div className="w-100 h-100">
                    {previewElements && files.length > 0 && preview
                        ? previewElements
                        : children}
                </div>
            </div>
        </>
    );
};

export default DropzoneComponent;
