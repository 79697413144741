import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import xss from "xss";
import INavigationPage from "./interfaces/INavigationPage";

import IconButton from "../common/buttons/iconButton/IconButton";
import SquareImg from "../common/images/squareImg/SquareImg";
import NavigationItem from "./components/navigationItem/NavigationItem";
import wappen from "./../../assets/resources/images/wappen.png";

import { ReactComponent as BurgerMenuIcon } from "./../../assets/resources/icons/menu-burger.svg";
import MobileNavigationButton from "./components/mobileNavigationButton/MobileNavigationButton";
import MobileNavigation from "./components/mobileNavigation/MobileNavigation";
import classNames from "classnames";

const Navigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [showMobileNavigation, setShowMobileNavigation] = useState(false);

    const navigationPages: INavigationPage[] = [
        {
            name: "Startseite",
            route: "/",
        },
        {
            name: "Spenden",
            route: "/spenden",
        },
        {
            name: "Ankündigungen",
            route: "/ankuendigungen",
        },
        {
            name: "Satzungen",
            route: "/satzungen",
        },
        {
            name: "Projekte",
            route: "/projekte",
        },
        {
            name: "Vorstand",
            route: "/vorstand",
        },
        {
            name: "Kontakt",
            route: "/kontakt",
        },
    ];

    const navigationPagesMobile: INavigationPage[] = [
        ...navigationPages,
        {
            name: "Beitritt",
            route: "/beitritt",
        },
    ];

    const onJoinBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigate("/beitritt");
    };

    return (
        <>
            <nav className="Navigation">
                <div className="row d-flex align-items-center">
                    <div className="col-3">
                        <Link to="/" className="text-decoration-none">
                            <div className="d-flex align-items-center">
                                <SquareImg
                                    image={wappen}
                                    alt="Wappen der Gemeinde Otterwisch"
                                    size={{
                                        width: "initial",
                                        height: "3.5rem",
                                    }}
                                ></SquareImg>
                                <span className="Navigation__logo-text logo-font ms-3">
                                    Förderverein
                                    <br />
                                    GS Otterwisch
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="d-none d-xxxl-block col-6">
                        <div className="Navigation__main-elements">
                            <ul className="m-0 p-0">
                                {navigationPages.map(
                                    (page: INavigationPage, index: number) => (
                                        <NavigationItem
                                            key={`key-nav-item-${index}`}
                                            name={page.name}
                                            to={page.route}
                                            active={
                                                xss(location.pathname) ===
                                                page.route
                                            }
                                        ></NavigationItem>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="d-none d-xl-block col-9 d-xxxl-none">
                        <div className="Navigation__main-elements clearfix">
                            <ul className="m-0 p-0 float-end">
                                {navigationPagesMobile.map(
                                    (page: INavigationPage, index: number) => (
                                        <NavigationItem
                                            key={`key-nav-item-${index}`}
                                            name={page.name}
                                            to={page.route}
                                            active={
                                                xss(location.pathname) ===
                                                page.route
                                            }
                                        ></NavigationItem>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="Navigation__mobileButton col-9 d-xl-none clearfix">
                        <div className="float-end">
                            <MobileNavigationButton
                                onClick={() =>
                                    setShowMobileNavigation(
                                        !showMobileNavigation
                                    )
                                }
                                Icon={BurgerMenuIcon}
                            ></MobileNavigationButton>
                        </div>
                    </div>
                    <div className="d-none d-xxxl-block col-3 clearfix">
                        <div className="float-end">
                            <IconButton
                                title="Jetzt beitreten"
                                onClick={onJoinBtnClick}
                                Icon={null}
                                primary={true}
                                padding={true}
                            ></IconButton>
                        </div>
                    </div>
                </div>
                <div
                    className={classNames("row d-xl-none", {
                        "d-none": !showMobileNavigation,
                    })}
                >
                    <div className="col-12">
                        <MobileNavigation
                            navigationPagesMobile={navigationPagesMobile}
                            showMobileNavigation={showMobileNavigation}
                            setShowMobileNavigation={setShowMobileNavigation}
                        ></MobileNavigation>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navigation;
