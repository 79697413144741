import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer/Footer";
import Navigation from "./components/navigation/Navigation";
import Ankuendigungen from "./pages/Ankuendigungen/Ankuendigungen";
import AnkuendigungenIndividual from "./pages/AnkuendigungenIndividual/AnkuendigungenIndividual";
import Beitritt from "./pages/Beitritt/Beitritt";
import Kontakt from "./pages/Kontakt/Kontakt";
import Projekte from "./pages/Projekte/Projekte";
import ProjekteIndividual from "./pages/ProjekteIndividual/ProjekteIndividual";
import Satzungen from "./pages/Satzungen/Satzungen";
import Spenden from "./pages/Spenden/Spenden";
import Startseite from "./pages/Startseite/Startseite";
import Vorstand from "./pages/Vorstand/Vorstand";
import "animate.css";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import Datenschutzerklaerung from "./pages/Datenschutzerklaerung/Datenschutzerklaerung";
import Impressum from "./pages/Impressum/Impressum";
import UserService from "./services/userService/UserService";
import { useAppDispatch } from "./hooks";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminDashboardMain from "./pages/AdminDashboard/pages/AdminDashboardMain/AdminDashboardMain";
import AdminDashboardKontaktanfragen from "./pages/AdminDashboard/pages/AdminDashboardKontaktanfragen/AdminDashboardKontaktanfragen";
import AdminDashboardForms from "./pages/AdminDashboard/pages/AdminDashboardForms/AdminDashboardForms";
import AdminDashboardUserManagement from "./pages/AdminDashboard/pages/AdminDashboardUserManagement/AdminDashboardUserManagement";
import AlertContainer from "./components/helpers/alertContainer/AlertContainer";
import AdminAdd from "./pages/AdminAdd/AdminAdd";
import Error404 from "./pages/errorPages/Error404/Error404";
import Error403 from "./pages/errorPages/Error403/Error403";
import { useAckee } from "./hooks/useAckee";

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useAckee(
    location.pathname,
    {
      server: "https://analytics.foerderverein-gs-otterwisch.de",
      domainId: "----ackee-record-id----",
    },
    {
      detailed: false,
      ignoreLocalhost: true,
      ignoreOwnVisits: true,
    }
  );

  useEffect(() => {
    UserService.initialize(dispatch);
  }, []);

  return (
    <div className="App">
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Navigation></Navigation>
            </div>
          </div>
          <div className="row">
            <Routes>
              <Route path="/" element={<Startseite></Startseite>} />
              <Route
                path="/ankuendigungen/:id"
                element={<AnkuendigungenIndividual></AnkuendigungenIndividual>}
              />
              <Route
                path="/ankuendigungen"
                element={<Ankuendigungen></Ankuendigungen>}
              />
              <Route path="/satzungen" element={<Satzungen></Satzungen>} />
              <Route
                path="/projekte/:id"
                element={<ProjekteIndividual></ProjekteIndividual>}
              />
              <Route path="/projekte" element={<Projekte></Projekte>} />
              <Route path="/vorstand" element={<Vorstand></Vorstand>} />
              <Route path="/kontakt" element={<Kontakt></Kontakt>} />
              <Route path="/beitritt" element={<Beitritt></Beitritt>} />
              <Route path="/spenden" element={<Spenden></Spenden>} />
              <Route path="/spenden/completed" element={<Spenden></Spenden>} />
              <Route
                path="/datenschutzerklaerung"
                element={<Datenschutzerklaerung></Datenschutzerklaerung>}
              />
              <Route path="/impressum" element={<Impressum></Impressum>} />
              <Route path="/admin" element={<AdminLogin></AdminLogin>} />
              <Route
                path="/admin/dashboard"
                element={<AdminDashboard></AdminDashboard>}
              >
                <Route
                  path=""
                  element={<AdminDashboardMain></AdminDashboardMain>}
                />
                <Route
                  path="kontaktanfragen"
                  element={
                    <AdminDashboardKontaktanfragen></AdminDashboardKontaktanfragen>
                  }
                />
                <Route
                  path="aufnahmeformulare"
                  element={<AdminDashboardForms></AdminDashboardForms>}
                />
                <Route
                  path="benutzerverwaltung"
                  element={
                    <AdminDashboardUserManagement></AdminDashboardUserManagement>
                  }
                />
              </Route>
              <Route path="/admin/add" element={<AdminAdd></AdminAdd>} />
              <Route path="/admin/add/:type" element={<AdminAdd></AdminAdd>} />
              <Route path="/error/404" element={<Error404></Error404>}></Route>
              <Route path="/error/403" element={<Error403></Error403>}></Route>
            </Routes>
          </div>
        </div>
      </div>
      <div className="footer bg-b700">
        <Footer></Footer>
      </div>
      <AlertContainer></AlertContainer>
    </div>
  );
}

export default App;
