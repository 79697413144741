import xss from "xss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    fetchAnkuendigungen,
    selectAllAnkuendigungen,
} from "../../features/ankuendigungen/ankuendigungenSlice";
import { AlertVariant } from "../../enums/AlertVariant";
import { RequestStatus } from "../../enums/RequestStatus";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { userCanEditContent } from "../../features/user/userSlice";
import { AnkuendigungenService } from "../../services/ankuendigungenService/AnkuendigungenService";

import ArticleCardList from "../../components/common/articleCardList/ArticleCardList";
import ConfirmationModal from "../../components/common/confirmationModal/ConfirmationModal";
import ArticleCardSkeleton from "../../components/common/articleCardSkeleton/ArticleCardSkeleton";
import FloatingActionButton from "../../components/common/buttons/floatingActionButton/FloatingActionButton";

import { ReactComponent as PlusIcon } from "./../../assets/resources/icons/plus.svg";
import { ReactComponent as TrashIcon } from "./../../assets/resources/icons/trash.svg";

const Ankuendigungen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userCanEdit = useAppSelector(userCanEditContent);

    const ankuendigungen = useAppSelector((state) =>
        selectAllAnkuendigungen(state)
    );

    const ankuendigungenStatus = useAppSelector(
        (state) => state.ankuendigungen.status
    );
    const error = useAppSelector((state) => state.ankuendigungen.error);

    useEffect(() => {
        if (error) {
            dispatch(
                alertAdded({
                    text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                    variant: AlertVariant.Danger,
                })
            );

            console.error(error);
        }
    }, [error]);

    const [ankuendigungIdToDelete, setAnkuendigungIdToDelete] = useState<
        number | null
    >(null);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState<boolean>(false);

    useEffect((): void => {
        if (ankuendigungenStatus === RequestStatus.Idle) {
            dispatch(fetchAnkuendigungen());
        }
    }, [ankuendigungenStatus, dispatch]);

    const onAnkuendigungClick = (id: string) => (): void => {
        const safeId = xss(id);
        const convertedId = parseInt(safeId);
        navigate(`/ankuendigungen/${convertedId}`);
    };

    //TODO: Bessere Fehlermeldungen
    if (ankuendigungenStatus === RequestStatus.Failed) {
        return (
            <div>
                <span>Seite konnte nicht geladen werden!</span>
            </div>
        );
    }

    const publishAnkuendigung = async (id: number): Promise<void> => {
        const result = await AnkuendigungenService.publish(id);
        if (result) dispatch(fetchAnkuendigungen());
    };

    const deleteAnkuendigung = (id: number): void => {
        setAnkuendigungIdToDelete(id);
        setShowDeleteConfirmationModal(true);
    };

    const onDeleteAnkuendigungConfirmed = async (): Promise<void> => {
        if (ankuendigungIdToDelete === null) return;

        setShowDeleteConfirmationModal(false);

        const successful = await AnkuendigungenService.delete(
            ankuendigungIdToDelete
        );

        if (successful) {
            dispatch(
                alertAdded({
                    text: "Ankündigung gelöscht",
                    variant: AlertVariant.Success,
                })
            );
            dispatch(fetchAnkuendigungen());
        } else {
            dispatch(
                alertAdded({
                    text: "Ankündigung konnte nicht gelöscht werden",
                    variant: AlertVariant.Danger,
                })
            );
        }
    };

    const onAddNewAnkuendigungBtnClick = (): void => {
        navigate("/admin/add/ankuendigung");
    };

    return (
        <>
            <div className="mb-5">
                <h1 className="text-center">Ankündigungen</h1>
                {ankuendigungenStatus === RequestStatus.Succeeded &&
                ankuendigungen.length === 0 ? (
                    <div className="text-center">
                        <i>Keine Einträge vorhanden</i>
                    </div>
                ) : (
                    <></>
                )}
                {ankuendigungenStatus === RequestStatus.Succeeded ? (
                    <ArticleCardList
                        articles={ankuendigungen}
                        onArticleClick={onAnkuendigungClick}
                        publishArticle={publishAnkuendigung}
                        deleteArticle={deleteAnkuendigung}
                    ></ArticleCardList>
                ) : (
                    <ArticleCardSkeleton></ArticleCardSkeleton>
                )}
                {userCanEdit ? (
                    <FloatingActionButton
                        description="Neuen Eintrag hinzufügen"
                        onClick={onAddNewAnkuendigungBtnClick}
                        Icon={PlusIcon}
                    ></FloatingActionButton>
                ) : (
                    <></>
                )}
                {showDeleteConfirmationModal && userCanEdit ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie die Ankündigung wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowDeleteConfirmationModal}
                        show={showDeleteConfirmationModal}
                        onCancel={() => setShowDeleteConfirmationModal(false)}
                        onConfirm={() => onDeleteAnkuendigungConfirmed()}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Ankuendigungen;
