import {
    createSlice,
    createEntityAdapter,
    createAsyncThunk,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import IArticle from "../../interfaces/IArticle";
import { RequestStatus } from "../../enums/RequestStatus";
import { AnkuendigungenService } from "../../services/ankuendigungenService/AnkuendigungenService";

const ankuendigungenAdapter = createEntityAdapter({
    sortComparer: (a: IArticle, b: IArticle) => b.timestamp - a.timestamp,
});

const initialState: {
    status: RequestStatus;
    error: string | null;
    ankuendigungen: EntityState<IArticle>;
    newestAnkuendigung: IArticle | null;
} = {
    status: RequestStatus.Idle,
    error: null,
    ankuendigungen: ankuendigungenAdapter.getInitialState(),
    newestAnkuendigung: null,
};

export const fetchAnkuendigungen = createAsyncThunk(
    "ankuendigungen/fetchAnkuendigungen",
    async () => {
        const response = await AnkuendigungenService.get();
        return response;
    }
);

export const ankuendigungenSlice = createSlice({
    name: "ankuendigungen",
    initialState,
    reducers: {
        setNewestAnkuendigung: (
            state,
            action: PayloadAction<IArticle | null>
        ) => {
            state.newestAnkuendigung = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAnkuendigungen.pending, (state, _) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(fetchAnkuendigungen.fulfilled, (state, action) => {
                state.status = RequestStatus.Succeeded;
                ankuendigungenAdapter.upsertMany(
                    state.ankuendigungen,
                    action.payload
                );
            })
            .addCase(fetchAnkuendigungen.rejected, (state, action) => {
                state.status = RequestStatus.Failed;
                state.error = action.error.message ?? "Error";
            });
    },
});

export default ankuendigungenSlice.reducer;

export const {
    selectAll: selectAllAnkuendigungen,
    selectById: selectAnkuendigungById,
} = ankuendigungenAdapter.getSelectors(
    (state: RootState) => state.ankuendigungen.ankuendigungen
);

export const { setNewestAnkuendigung } = ankuendigungenSlice.actions;
