import xss from "xss";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../../hooks";
import UserService from "../../../../services/userService/UserService";

import IconButton from "../../../../components/common/buttons/iconButton/IconButton";
import HorizontalLine from "../../../../components/common/horizontalLine/HorizontalLine";
import INavigationPage from "../../../../components/navigation/interfaces/INavigationPage";

import { ReactComponent as LogoutIcon } from "./../../../../assets/resources/icons/sign-out-alt.svg";

const AdminNavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const subpages: INavigationPage[] = [
        {
            name: "Übersicht",
            route: "/admin/dashboard",
        },
        {
            name: "Kontaktanfragen",
            route: "/admin/dashboard/kontaktanfragen",
        },
        {
            name: "Aufnahmeformulare",
            route: "/admin/dashboard/aufnahmeformulare",
        },
        {
            name: "Benutzerverwaltung",
            route: "/admin/dashboard/benutzerverwaltung",
        },
    ];

    const onLogoutBtnClick = async (): Promise<void> => {
        const logoutSuccess = await UserService.logout(dispatch);

        if (logoutSuccess) {
            navigate("/admin");
        }
    };

    return (
        <>
            <div className="AdminNavBar bg-n200 w-100 p-3">
                {subpages.map((page) => (
                    <div
                        key={`key-admin-nav-bar-${page.name}`}
                        className="mb-3"
                    >
                        <IconButton
                            title={page.name}
                            onClick={() => navigate(page.route)}
                            Icon={null}
                            primary={xss(location.pathname) === page.route}
                        ></IconButton>
                    </div>
                ))}

                <HorizontalLine></HorizontalLine>

                <IconButton
                    title="Abmelden"
                    onClick={onLogoutBtnClick}
                    Icon={LogoutIcon}
                    primary={false}
                ></IconButton>
            </div>
        </>
    );
};

export default AdminNavBar;
