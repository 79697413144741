import { MouseEvent } from "react";
import SmallIconButton from "../../common/buttons/smallIconButton/SmallIconButton";

import { ReactComponent as PaperPlaneIcon } from "./../../../assets/resources/icons/paper-plane.svg";
import { ReactComponent as PencilIcon } from "./../../../assets/resources/icons/pencil.svg";
import { ReactComponent as TrashIcon } from "./../../../assets/resources/icons/trash.svg";

export interface ContentActionButtonsProps {
    onEditClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onDeleteClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onPublishClick?:
        | ((event: React.MouseEvent<HTMLButtonElement>) => void)
        | null;
}

const ContentActionButtons = ({
    onEditClick,
    onDeleteClick,
    onPublishClick,
}: ContentActionButtonsProps) => {
    return (
        <>
            <div className="ContentActionButtons">
                {onPublishClick ? (
                    <>
                        <SmallIconButton
                            description="Eintrag veröffentlichen"
                            onClick={onPublishClick}
                            Icon={PaperPlaneIcon}
                            primary={true}
                        ></SmallIconButton>
                        <div className="mx-2"></div>
                    </>
                ) : (
                    <></>
                )}

                {/* <SmallIconButton
                    description="Eintrag bearbeiten"
                    onClick={onEditClick}
                    Icon={PencilIcon}
                    warning={true}
                ></SmallIconButton>
                <div className="mx-2"></div> */}
                <SmallIconButton
                    description="Eintrag löschen"
                    onClick={onDeleteClick}
                    Icon={TrashIcon}
                    danger={true}
                ></SmallIconButton>
            </div>
        </>
    );
};

export default ContentActionButtons;
