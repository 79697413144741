import { RequestMethod } from "../../enums/RequestMethod";
import { setNewestAnkuendigung } from "../../features/ankuendigungen/ankuendigungenSlice";
import IArticle from "../../interfaces/IArticle";
import { AppDispatch } from "../../store";
import CommonService from "../commonService/CommonService";
import RequestService from "../requestService/RequestService";
import { IUploadAnkuendigung } from "../../interfaces/upload/IUploadAnkuendigung";
import { DatabaseDataService } from "../databaseDataService/DatabaseDataService";

export class AnkuendigungenService extends DatabaseDataService {
    protected static urlName = "ankuendigungen";
    protected static friendlyName = "Ankündigung";
    protected static friendlyNamePlural = "Ankündigungen";

    public static async get(): Promise<IArticle[]> {
        return this._get<IArticle>();
    }

    public static async loadNewest(dispatch: AppDispatch): Promise<void> {
        try {
            const response = await RequestService.makeRequest<
                IArticle | { noData: null }
            >("/api/newest-ankuendigung", RequestMethod.GET);

            if ("error" in response.data) {
                throw new Error(
                    `${this.friendlyNamePlural} konnten nicht geladen werden.`
                );
            } else if ("noData" in response.data) {
                // there are no ankuendigungen
                return;
            } else {
                dispatch(setNewestAnkuendigung(response.data));
            }
        } catch (err) {
            console.error(err);
        }
    }

    public static async add(
        data: IUploadAnkuendigung,
        author: string
    ): Promise<boolean> {
        let success: boolean = false;

        try {
            const titleImage = data.galeryImages[0];

            const titleImagePath = await CommonService.uploadArticleImage(
                titleImage.image
            );

            // Send information
            const ankuendigungRequestBody = {
                title: data.title,
                preview_text: data.previewText,
                author: author,
                content: JSON.stringify(data.content),
                title_image: titleImagePath,
                title_image_alt: titleImage.image_alt,
                title_image_subtitle: titleImage.image_subtitle,
                draft: data.draft,
            };
            const ankuendigungRequestResponse =
                await RequestService.makeRequest<{
                    success: Boolean;
                    id: number;
                }>(
                    `/api/add/${this.urlName}`,
                    RequestMethod.POST,
                    ankuendigungRequestBody
                );

            if (
                "error" in ankuendigungRequestResponse.data ||
                ankuendigungRequestResponse.data.success === false
            ) {
                throw new Error(
                    `${this.friendlyName} konnte nicht hinzugefügt werden.`
                );
            }

            const ankuendigungId = ankuendigungRequestResponse.data.id;

            // Upload galery images
            data.galeryImages.forEach(async (image) => {
                const imageRequestFormData = new FormData();
                imageRequestFormData.append("image_alt", image.image_alt);
                imageRequestFormData.append(
                    "image_subtitle",
                    image.image_subtitle
                );
                imageRequestFormData.append("article_id", ankuendigungId + "");
                imageRequestFormData.append("type", "article-image");
                imageRequestFormData.append("image", image.image);

                const imageRequestResponse = await RequestService.makeRequest<{
                    success: Boolean;
                }>(
                    "/api/add/ankuendigung-galeriebild",
                    RequestMethod.POST,
                    undefined,
                    imageRequestFormData,
                    {}
                );

                if (
                    "error" in imageRequestResponse.data ||
                    imageRequestResponse.data.success === false
                ) {
                    throw new Error(
                        "Galeriebild konnte nicht hinzugefügt werden."
                    );
                }
            });

            success = true;
        } catch (err) {
            console.error(err);
        }

        return success;
    }

    public static async publish(id: number): Promise<boolean> {
        return this._publish(id);
    }

    public static async delete(id: number): Promise<boolean> {
        return this._delete(id);
    }
}
