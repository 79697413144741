import DOMPurify from "dompurify";
import IArticle from "../../../interfaces/IArticle";
import ImageSlider from "../imageSlider/ImageSlider";
import SlateEditor from "../slateEditor/SlateEditor";

export interface SingleArticlePageProps {
    article: IArticle;
}

const SingleArticlePage = ({ article }: SingleArticlePageProps) => {
    const articleContent = JSON.parse(DOMPurify.sanitize(article.content));

    return (
        <>
            <div className="SingleArticlePage">
                <div className="d-flex justify-content-between tc-n500 l2-r">
                    <span>{article.author}</span>
                    <span>
                        {new Date(article.timestamp * 1000).toLocaleString()} ·{" "}
                        {article.reading_time} min Lesezeit
                    </span>
                </div>
                <h1 className="text-center">{article.title}</h1>
                {article.images && article.images.length > 0 ? (
                    <>
                        <ImageSlider images={article.images}></ImageSlider>
                    </>
                ) : (
                    <>
                        <div className="SingleArticlePage__header-img">
                            <img
                                src={article.title_image}
                                alt={article.title_image_alt}
                            />
                        </div>
                        <div className="mt-2 tc-n500 l2-r">
                            <span>{article.title_image_subtitle}</span>
                        </div>
                    </>
                )}

                <div className="mt-5">
                    <SlateEditor
                        readOnly={true}
                        initialValue={articleContent}
                    ></SlateEditor>
                </div>
            </div>
        </>
    );
};

export default SingleArticlePage;
