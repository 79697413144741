import { useSelector } from "react-redux";
import IArticle from "../../../interfaces/IArticle";
import ArticleCard from "../articleCard/ArticleCard";
import { userCanEditContent } from "../../../features/user/userSlice";

export interface ArticleCardListProps {
    articles: IArticle[];
    onArticleClick: (id: string) => () => void;
    publishArticle: (id: number) => void;
    deleteArticle: (id: number) => void;
}

const ArticleCardList = ({
    articles,
    onArticleClick,
    publishArticle,
    deleteArticle,
}: ArticleCardListProps) => {
    const userCanEdit = useSelector(userCanEditContent);

    return (
        <>
            <div>
                {articles.map((article, index) => {
                    if (userCanEdit === false && article.draft === 1) {
                        return <></>;
                    }

                    return (
                        <div key={`key-article-${article.id}`}>
                            <ArticleCard
                                article={article}
                                onClick={onArticleClick(article.id + "")}
                                publishArticle={publishArticle}
                                deleteArticle={deleteArticle}
                            ></ArticleCard>
                            {index < articles.length - 1 ? (
                                <div className="mt-5"></div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ArticleCardList;
