import { RootState } from "./../../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import IUser from "../../interfaces/IUser";

export const initialUserState: IUser = {
    id: -1,
    username: "",
    last_login: 0,
    is_admin: 0,
    only_read: 0,
    email: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        setUser: (state, action: PayloadAction<IUser>) => {
            return action.payload;
        },
    },
});

// TODO CHECK
export const userIsLoggedIn = (state: RootState) => state.user.id !== -1;

export const userCanEditContent = (state: RootState) =>
    userIsLoggedIn(state) && !state.user.only_read;

export const userIsAdmin = (state: RootState) =>
    userIsLoggedIn(state) && state.user.is_admin;

export default userSlice.reducer;

export const { setUser } = userSlice.actions;
