import { ReactComponent as CrossIcon } from "../../../../assets/resources/icons/cross.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/resources/icons/heart.svg";
import SmallIconButton from "../../../../components/common/buttons/smallIconButton/SmallIconButton";

export interface DonationCompletedModalProps {
  setShow: Function;
}

const DonationCompletedModal = ({ setShow }: DonationCompletedModalProps) => {
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShow(false);
  };

  return (
    <>
      <div className="DonationCompletedModal__bg">
        <div className="DonationCompletedModal shadow">
          <div className="row">
            <div className="col-12">
              <p className="h2 mt-5">
                Im Namen des gesamten Fördervereins sagen wir:
              </p>
              <p className="h2">VIELEN DANK</p>
              <HeartIcon className="DonationCompletedModal__icon"></HeartIcon>
            </div>
          </div>
          <div className="DonationCompletedModal__closeBtn">
            <SmallIconButton
              description="Popup schließen"
              onClick={closeModal}
              Icon={CrossIcon}
            ></SmallIconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationCompletedModal;
