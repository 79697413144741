import { ReactComponent as CancelIcon } from "../../../assets/resources/icons/cancel.svg";

const Error404 = () => {
    return (
        <>
            <div className="Error404">
                <div className="Error404__content">
                    <CancelIcon className="Error__404__icon"></CancelIcon>

                    <h1>Error 404 - Seite nicht gefunden</h1>
                </div>
            </div>
        </>
    );
};

export default Error404;
