export enum SlateEditorFormatType {
    Bold = "bold",
    Italic = "italic",
    Underline = "underline",
    Code = "code",
    HeadingOne = "heading-one",
    HeadingTwo = "heading-two",
    BlockQuote = "block-quote",
    NumberedList = "numbered-list",
    BulletedList = "bulleted-list",
    AlignLeft = "left",
    AlignCenter = "center",
    AlignRight = "right",
    AlignJustify = "justify",
    ListItem = "list-item",
    Paragraph = "paragraph",
    Image = "image",
}
