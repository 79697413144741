import xss from "xss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../hooks";
import { ButtonType } from "../../enums/ButtonType";
import UserService from "../../services/userService/UserService";

import Input from "../../components/common/forms/input/Input";
import IconButton from "../../components/common/buttons/iconButton/IconButton";
import HCaptchaComponent from "../../components/common/forms/hcaptchaComponent/HCaptchaComponent";

import { ReactComponent as SignInIcon } from "./../../assets/resources/icons/sign-in-alt.svg";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { AlertVariant } from "../../enums/AlertVariant";

const AdminLogin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loginFailed, setLoginFailed] = useState<boolean>(false);

    const [hcaptchaToken, setHcaptchaToken] = useState<string | null>(null);

    const onFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (hcaptchaToken === null || hcaptchaToken === "") {
            console.warn("Not completed Captcha!");

            dispatch(
                alertAdded({
                    text: "Bitte bestätigen Sie, dass Sie ein Mensch sind.",
                    variant: AlertVariant.Warning,
                })
            );

            return;
        }

        const success = await UserService.login(
            xss(username),
            xss(password),
            xss(hcaptchaToken),
            dispatch
        );

        if (success) navigate("/admin/dashboard");

        setLoginFailed(!success);
    };

    return (
        <>
            <div className="AdminLogin">
                <div className="AdminLogin__content">
                    <h1 className="text-center">Admin Login</h1>
                    <form className="" onSubmit={onFormSubmit}>
                        <Input
                            id="admin-username"
                            type="text"
                            placeholder="Nutzername"
                            required={true}
                            onChange={(
                                event: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => setUsername(event.target.value)}
                        ></Input>
                        <Input
                            id="admin-password"
                            type="password"
                            placeholder="Passwort"
                            required={true}
                            onChange={(
                                event: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => setPassword(event.target.value)}
                        ></Input>
                        {loginFailed ? (
                            <div className="AdminLogin__error">
                                Falscher Nutzername oder Passwort!
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="mt-4 width-fit-content mx-auto">
                            <HCaptchaComponent
                                setToken={setHcaptchaToken}
                            ></HCaptchaComponent>
                        </div>

                        <div className="mt-3">
                            <IconButton
                                title="Anmelden"
                                onClick={() => {}}
                                type={ButtonType.Submit}
                                Icon={SignInIcon}
                                primary={true}
                            ></IconButton>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AdminLogin;
