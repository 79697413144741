import classNames from "classnames";
import { ReactComponent as AngleRightIcon } from "./../../../../assets/resources/icons/angle-right.svg";
import ContentActionButtons from "../../../../components/admin/contentActionButtons/ContentActionButtons";
import { useAppSelector } from "../../../../hooks";
import {
    userCanEditContent,
    userIsLoggedIn,
} from "../../../../features/user/userSlice";
import ISatzung from "../../../../interfaces/ISatzung";

export interface SatzungenCardProps {
    satzung: ISatzung;
    publishSatzung: (id: number) => void;
    deleteSatzung: (id: number) => void;
}

const SatzungenCard = ({
    satzung,
    publishSatzung,
    deleteSatzung,
}: SatzungenCardProps) => {
    const isLoggedIn = useAppSelector(userIsLoggedIn);
    const canEdit = useAppSelector(userCanEditContent);

    const onPublishClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        publishSatzung(satzung.id);
    };

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        deleteSatzung(satzung.id);
    };

    return (
        <>
            <div
                className={classNames("SatzungenCard shadow p-1", {
                    "SatzungenCard--draft": satzung.draft === 1,
                })}
                onClick={() => window.open(satzung.url, "_blank")}
            >
                <h3 className="text-center">{satzung.title}</h3>
                <div className="SatzungenCard__icon">
                    <AngleRightIcon
                        height="1.5rem"
                        width="1.5rem"
                    ></AngleRightIcon>
                </div>
                {isLoggedIn && canEdit ? (
                    <ContentActionButtons
                        onEditClick={(e) => {}}
                        onDeleteClick={onDeleteClick}
                        onPublishClick={
                            satzung.draft === 1 ? onPublishClick : null
                        }
                    ></ContentActionButtons>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default SatzungenCard;
