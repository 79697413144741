import classNames from "classnames";

import IconButton from "../../../../components/common/buttons/iconButton/IconButton";
import DropzoneComponent from "../../../../components/common/forms/dropzoneComponent/DropzoneComponent";
import HCaptchaComponent from "../../../../components/common/forms/hcaptchaComponent/HCaptchaComponent";

import { ReactComponent as TrashIcon } from "./../../../../assets/resources/icons/trash.svg";
import { ReactComponent as FailIcon } from "./../../../../assets/resources/icons/cancel.svg";
import { ReactComponent as SuccessIcon } from "./../../../../assets/resources/icons/checked.svg";
import { ReactComponent as UploadFileIcon } from "./../../../../assets/resources/icons/upload-file.svg";
import { ReactComponent as PaperPlaneIcon } from "./../../../../assets/resources/icons/paper-plane.svg";

export interface BeitrittUploadSideProps {
    successfulSendData: boolean | null;
    files: (File & { preview?: string })[];
    setFiles: Function;
    onSubmitBtnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    setHcaptchaToken: (hcaptchaToken: string) => void;
}

const BeitrittUploadSide = ({
    successfulSendData,
    files,
    setFiles,
    onSubmitBtnClick,
    setHcaptchaToken,
}: BeitrittUploadSideProps) => {
    const onResetBtnClick = () => {
        // Delete files
        files.forEach((file) => {
            if (file.preview) {
                URL.revokeObjectURL(file.preview);
            }
        });
        setFiles([]);
    };

    if (successfulSendData === true) {
        return (
            <>
                <SuccessIcon width="35%"></SuccessIcon>
                <p className="b0-sb w-75 mt-4 text-center">
                    Vielen Dank! Ihre Anmeldung wird schnellstmöglich
                    bearbeitet.
                </p>
            </>
        );
    } else if (successfulSendData === false) {
        return (
            <>
                <FailIcon width="35%"></FailIcon>
                <p className="b0-sb w-75 mt-4 text-center">
                    Leider ist etwas schiefgelaufen. Bitte versuchen Sie es
                    erneut.
                </p>
            </>
        );
    }

    return (
        <>
            <DropzoneComponent
                accept={{
                    "application/pdf": [],
                    "image/*": [],
                }}
                multiple={false}
                files={files}
                setFiles={setFiles}
            >
                <div className="w-100 h-100 d-flex justify-content-center align-items-center p-4">
                    <div className="w-50">
                        <UploadFileIcon
                            className="ic-b700 mx-auto d-block"
                            height="6rem"
                            width="6rem"
                        ></UploadFileIcon>
                        <p className="b1-sb text-center mt-3">
                            Ausgefülltes Dokument hierher ziehen zum hochladen
                        </p>
                        <div className="d-flex align-items-center my-4">
                            <div className="custom-hr"></div>
                            <span className="tc-n300 mx-3">ODER</span>
                            <div className="custom-hr"></div>
                        </div>
                        <IconButton
                            title="Dateien durchsuchen"
                            onClick={() => {}}
                            Icon={null}
                            primary={true}
                        ></IconButton>
                    </div>
                </div>
            </DropzoneComponent>
            <div
                className={classNames("mt-3 w-100", {
                    "d-none": files.length === 0,
                })}
            >
                <div className="row">
                    <div className="col-12">
                        <div className="width-fit-content mx-auto">
                            <HCaptchaComponent
                                setToken={setHcaptchaToken}
                            ></HCaptchaComponent>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <IconButton
                            title="Zurücksetzen"
                            onClick={onResetBtnClick}
                            Icon={TrashIcon}
                            primary={false}
                        ></IconButton>
                    </div>
                    <div className="col-6">
                        <IconButton
                            title="Absenden"
                            onClick={onSubmitBtnClick}
                            Icon={PaperPlaneIcon}
                            primary={true}
                        ></IconButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BeitrittUploadSide;
