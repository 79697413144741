import { RequestStatus } from "./../../enums/RequestStatus";
import {
    createSlice,
    createEntityAdapter,
    createAsyncThunk,
    EntityState,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import IArticle from "../../interfaces/IArticle";
import {ProjekteService} from "../../services/projekteService/ProjekteService";

const projekteAdapter = createEntityAdapter({
    sortComparer: (a: IArticle, b: IArticle) => b.timestamp - a.timestamp,
});

const initialState: {
    status: RequestStatus;
    error: string | null;
    projekte: EntityState<IArticle>;
} = {
    status: RequestStatus.Idle,
    error: null,
    projekte: projekteAdapter.getInitialState(),
};

export const fetchProjekte = createAsyncThunk(
    "projekte/fetchProjekte",
    async () => await ProjekteService.get()
);

export const projekteSlice = createSlice({
    name: "projekte",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchProjekte.pending, (state, _) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(fetchProjekte.fulfilled, (state, action) => {
                state.status = RequestStatus.Succeeded;
                projekteAdapter.upsertMany(state.projekte, action.payload);
            })
            .addCase(fetchProjekte.rejected, (state, action) => {
                state.status = RequestStatus.Failed;
                state.error = action.error.message ?? "Error";
            });
    },
});

export default projekteSlice.reducer;

export const { selectAll: selectAllProjekte, selectById: selectProjektById } =
    projekteAdapter.getSelectors((state: RootState) => state.projekte.projekte);
