import { DatabaseDataService } from "../databaseDataService/DatabaseDataService";
import IEvent from "../../interfaces/IEvent";
import { IUploadEvent } from "../../interfaces/upload/IUploadEvent";

export class NaechsteEventsService extends DatabaseDataService {
    protected static urlName = "naechste-events";
    protected static friendlyName = "Event";
    protected static friendlyNamePlural = "Events";

    public static async get(): Promise<IEvent[]> {
        return this._get<IEvent>();
    }

    public static async add(data: IUploadEvent): Promise<boolean> {
        return this._add<IUploadEvent>(data);
    }

    public static async publish(id: number): Promise<boolean> {
        return this._publish(id);
    }

    public static async delete(id: number): Promise<boolean> {
        return this._delete(id);
    }
}
