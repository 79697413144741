import React from "react";

export interface InputProps {
    id: string;
    type: string;
    placeholder: string;
    required: boolean;
    disabled?: boolean;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    value?: any;
}

const Input = ({
    id,
    type,
    placeholder,
    required,
    disabled,
    onChange,
    value = undefined,
}: InputProps) => {
    return (
        <>
            <div className="mb-3">
                <label className="form-label l2-sb mb-2" htmlFor={id}>
                    {placeholder}
                </label>
                <input
                    type={type}
                    className="form-control bg-n100"
                    id={id}
                    placeholder={placeholder}
                    name={id}
                    onChange={onChange}
                    required={required}
                    value={value}
                    disabled={disabled ?? false}
                />
            </div>
        </>
    );
};

export default Input;
