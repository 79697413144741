import { Link } from "react-router-dom";

export interface InlineButtonProps {
    text: string;
    to: string;
    staticUnderline?: boolean;
    light?: boolean;
}

const InlineButton = ({
    text,
    to,
    staticUnderline = false,
    light = false,
}: InlineButtonProps) => {
    return (
        <>
            <Link
                className={`InlineButton ${
                    staticUnderline ? "InlineButton--staticUnderline" : ""
                } ${light ? "InlineButton--light" : ""}`}
                to={to}
            >
                {text}
            </Link>
        </>
    );
};

export default InlineButton;
