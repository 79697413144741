import { RenderLeafProps } from "slate-react";

export const SlateEditorLeaf = ({
    attributes,
    children,
    leaf,
}: RenderLeafProps) => {
    if (leaf.bold) children = <strong>{children}</strong>;

    if (leaf.code) children = <code>{children}</code>;

    if (leaf.italic) children = <em>{children}</em>;

    if (leaf.underline) children = <u>{children}</u>;

    return (
        <>
            <span {...attributes}>{children}</span>
        </>
    );
};
