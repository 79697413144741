const ArticleCardSkeleton = () => {
    return (
        <>
            <div className="ArticleCardSkeleton w-100">
                <div className="ArticleCardSkeleton__img-container placeholder-glow">
                    <div className="placeholder w-100 h-100"></div>
                </div>
                <div className="ArticleCardSkeleton__info w-100">
                    <div className="w-100">
                        <div className="ArticleCardSkeleton__attentionText l2-sb tc-r700">
                            <span></span>
                        </div>
                        <h3 className="mt-4 placeholder-glow">
                            <span className="placeholder col-7"></span>
                        </h3>
                        <span className="card-text placeholder-glow">
                            <span className="placeholder col-8 me-1"></span>
                            <span className="placeholder col-3 me-1"></span>
                            <span className="placeholder col-6 me-1"></span>
                            <span className="placeholder col-4 me-1"></span>
                            <span className="placeholder col-3 me-1"></span>
                        </span>
                    </div>
                    <div className="l2-r placeholder-glow">
                        <span className="placeholder col-8"></span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticleCardSkeleton;
