import React from "react";
import { RenderElementProps } from "slate-react";
import { SlateEditorFormatType } from "../../../../../../../enums/SlateEditorFormatType";
import SlateEditorCustomImage from "./components/slateEditorCustomImage/SlateEditorCustomImage";
import { CustomElement } from "../../../../SlateEditorTypes";

export interface SlateEditorCustomElementProps extends RenderElementProps {
    readOnly: boolean;
    onImageDeleted: (image: CustomElement) => Promise<void>;
}

const SlateEditorCustomElement = ({
    attributes,
    element,
    children,
    readOnly,
    onImageDeleted,
}: SlateEditorCustomElementProps) => {
    const style: Partial<React.CSSProperties> = {
        textAlign: element.align ? (element.align as any) : undefined,
    };

    switch (element.type) {
        case SlateEditorFormatType.BlockQuote:
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case SlateEditorFormatType.BulletedList:
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case SlateEditorFormatType.HeadingOne:
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case SlateEditorFormatType.HeadingTwo:
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case SlateEditorFormatType.ListItem:
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case SlateEditorFormatType.NumberedList:
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        case SlateEditorFormatType.Image:
            return (
                <SlateEditorCustomImage
                    attributes={attributes}
                    element={element}
                    children={children}
                    readOnly={readOnly}
                    onImageDeleted={onImageDeleted}
                ></SlateEditorCustomImage>
            );
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};

export default SlateEditorCustomElement;
