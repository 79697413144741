import { AlertVariant } from "./../../enums/AlertVariant";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IAlert from "../../interfaces/IAlert";

const initialState: {
    idCount: number;
    alerts: IAlert[];
} = {
    idCount: 0,
    alerts: [],
};

export const alertsSlice = createSlice({
    name: "alerts",
    initialState,
    reducers: {
        alertAdded(
            state,
            action: PayloadAction<{ text: string; variant: AlertVariant }>
        ) {
            const alert = { id: state.idCount, ...action.payload };

            state.alerts.push(alert);
            state.idCount++;
        },
        alertRemoved(state, action: PayloadAction<IAlert>) {
            const newAlerts = state.alerts.filter(
                (alert) => alert.id !== action.payload.id
            );

            return { idCount: state.idCount, alerts: newAlerts };
        },
    },
});

export default alertsSlice.reducer;

export const { alertAdded, alertRemoved } = alertsSlice.actions;
