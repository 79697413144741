import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
    fetchProjekte,
    selectProjektById,
} from "../../features/projekte/projekteSlice";
import IArticle from "../../interfaces/IArticle";
import { RequestStatus } from "../../enums/RequestStatus";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CommonService from "../../services/commonService/CommonService";

import SingleArticlePage from "../../components/common/singleArticlePage/SingleArticlePage";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { AlertVariant } from "../../enums/AlertVariant";

const ProjekteIndividual = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const safeId: number | null = id
        ? CommonService.convertAndCheckId(id)
        : null;

    let projekt: IArticle | undefined = useAppSelector((state) =>
        selectProjektById(state, safeId ?? -1)
    );

    const projekteStatus = useAppSelector((state) => state.projekte.status);
    const error = useAppSelector((state) => state.projekte.error);

    useEffect(() => {
        if (error) {
            dispatch(
                alertAdded({
                    text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                    variant: AlertVariant.Danger,
                })
            );

            console.error(error);
        }
    }, [error]);

    useEffect((): void => {
        if (projekteStatus === RequestStatus.Idle) {
            dispatch(fetchProjekte());
        }
    }, [projekteStatus, dispatch]);

    useEffect(() => {
        if (
            projekteStatus === RequestStatus.Succeeded &&
            projekt === undefined
        ) {
            navigate("/error/404");
        }
    }, [projekt, projekteStatus]);

    return (
        <>
            <div className="ProjekteIndividual my-5">
                <div className="row">
                    <div className="col-0 col-md-1 col-lg-2 col-xxl-3"></div>
                    <div className="col-12 col-md-10 col-lg-8 col-xxl-6">
                        {!projekt ? (
                            <>Lädt ...</>
                        ) : (
                            <SingleArticlePage
                                article={projekt}
                            ></SingleArticlePage>
                        )}
                    </div>
                    <div className="col-0 col-md-1 col-lg-2 col-xxl-3"></div>
                </div>
            </div>
        </>
    );
};

export default ProjekteIndividual;
