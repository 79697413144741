import { DatabaseDataService } from "../databaseDataService/DatabaseDataService";
import CommonService from "../commonService/CommonService";

import ISatzung from "../../interfaces/ISatzung";
import { IUploadSatzung } from "../../interfaces/upload/IUploadSatzung";

export class SatzungenService extends DatabaseDataService {
    protected static urlName = "satzungen";
    protected static friendlyName = "Satzung";
    protected static friendlyNamePlural = "Satzungen";

    public static async get(): Promise<ISatzung[]> {
        return this._get<ISatzung>();
    }

    public static async add(data: IUploadSatzung): Promise<boolean> {
        let success: boolean = false;

        try {
            if (data.document === null) {
                throw new Error("Dokument ist nicht vorhanden.");
            }

            const documentPath = await CommonService.uploadDocument(
                data.document
            );

            if (documentPath === "") {
                throw new Error("Dokument konnte nicht hochgeladen werden.");
            }

            const requestBody = {
                title: data.title,
                url: documentPath,
                draft: data.draft,
            };

            const requestSuccess = this._add<typeof requestBody>(requestBody);

            if (!requestSuccess) {
                throw new Error(
                    `${this.friendlyName} konnte nicht hinzugefügt werden.`
                );
            }

            success = true;
        } catch (err) {
            console.error(err);
        }

        return success;
    }

    public static async publish(id: number): Promise<boolean> {
        return this._publish(id);
    }

    public static async delete(id: number): Promise<boolean> {
        return this._delete(id);
    }
}
