import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IAnmeldeformular from "../../interfaces/IAnmeldeformular";
import IKontaktanfrage from "../../interfaces/IKontaktanfrage";

const initialState: {
    anmeldeformulare: IAnmeldeformular[];
    kontaktanfragen: IKontaktanfrage[];
} = {
    anmeldeformulare: [],
    kontaktanfragen: [],
};

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setAnmeldeformulare: (
            state,
            action: PayloadAction<IAnmeldeformular[]>
        ) => {
            state.anmeldeformulare = action.payload;
        },
        setKontaktanfragen: (
            state,
            action: PayloadAction<IKontaktanfrage[]>
        ) => {
            state.kontaktanfragen = action.payload;
        },
    },
});

export default adminSlice.reducer;

export const { setAnmeldeformulare, setKontaktanfragen } = adminSlice.actions;
