import IArticleImageAdd from "../../../../../../../interfaces/IArticleImageAdd";
import SmallIconButton from "../../../../../../common/buttons/smallIconButton/SmallIconButton";
import { getPreviewImage } from "../../../../../../common/forms/dropzoneComponent/DropzoneComponent";
import Input from "../../../../../../common/forms/input/Input";

import { ReactComponent as CrossIcon } from "../../../../../../../assets/resources/icons/cross.svg";
import { ChangeEvent } from "react";

export interface EditImagePreviewCardProps {
    index: number;
    images: IArticleImageAdd[];
    setImages: Function;
}

const EditImagePreviewCard = ({
    index,
    images,
    setImages,
}: EditImagePreviewCardProps) => {
    const image = images[index];

    // TODO: make better
    const onImgAltChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ): void => {
        const modifiedImage: IArticleImageAdd = {
            ...image,
            image_alt: e.target.value,
        };

        setImages(
            images.map((img, i) => {
                if (i === index) {
                    return modifiedImage;
                }
                return img;
            })
        );
    };

    const onImgSubtitleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ): void => {
        const modifiedImage: IArticleImageAdd = {
            ...image,
            image_subtitle: e.target.value,
        };

        setImages(
            images.map((img, i) => {
                if (i === index) {
                    return modifiedImage;
                }
                return img;
            })
        );
    };

    const onClickDeleteBtn = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        if (image.image.preview) {
            URL.revokeObjectURL(image.image.preview);
        }

        setImages(images.filter((_, i) => i !== index));
    };

    return (
        <>
            <div className="EditImagePreviewCard rounded me-3">
                <div className="EditImagePreviewCard__img">
                    {getPreviewImage(image.image)}
                </div>
                <div className="EditImagePreviewCard__fields">
                    <Input
                        id={`edit-image-alt-${image.image.name}-${index}`}
                        type="text"
                        placeholder="Alternativ-Text"
                        required={true}
                        value={image.image_alt}
                        onChange={(e) => {
                            onImgAltChange(e, index);
                        }}
                    ></Input>
                    <Input
                        id={`edit-image-subtitle-${image.image.name}-${index}`}
                        type="text"
                        placeholder="Untertitel (optional)"
                        required={false}
                        value={image.image_subtitle}
                        onChange={(e) => {
                            onImgSubtitleChange(e, index);
                        }}
                    ></Input>
                </div>
                <div className="EditImagePreviewCard__del-btn">
                    <SmallIconButton
                        description="Bild löschen"
                        onClick={onClickDeleteBtn}
                        Icon={CrossIcon}
                        small={true}
                        danger={true}
                    ></SmallIconButton>
                </div>
            </div>
        </>
    );
};

export default EditImagePreviewCard;
