import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { userIsLoggedIn } from "../../../../features/user/userSlice";
import IAnmeldeformular from "../../../../interfaces/IAnmeldeformular";
import AufnahmeformularService from "../../../../services/aufnahmeformularService/AufnahmeformularService";

import ConfirmationModal from "../../../../components/common/confirmationModal/ConfirmationModal";
import SmallIconButton from "../../../../components/common/buttons/smallIconButton/SmallIconButton";

import { ReactComponent as DownloadIcon } from "./../../../../assets/resources/icons/download.svg";
import { ReactComponent as TrashIcon } from "./../../../../assets/resources/icons/trash.svg";

const AdminDashboardForms = () => {
    const dispatch = useAppDispatch();

    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);

    const isLoggedIn = useAppSelector(userIsLoggedIn);
    const aufnahmeformulare = useAppSelector(
        (state) => state.admin.anmeldeformulare
    );

    const [formIdToDelete, setFormIdToDelete] = useState<number | null>(null);

    useEffect(() => {
        if (isLoggedIn) {
            AufnahmeformularService.load(dispatch);
        }
    }, [dispatch, isLoggedIn]);

    const onDownloadBtnClick = (el: IAnmeldeformular): void => {
        window.open(el.filepath, "_blank");
    };

    const onDeleteBtnClick = (id: number): void => {
        setFormIdToDelete(id);
        setShowConfirmationModal(true);
    };

    const onConfirmDeleteClick = async (): Promise<void> => {
        setShowConfirmationModal(false);

        if (formIdToDelete !== null && formIdToDelete >= 0) {
            const result = await AufnahmeformularService.delete(formIdToDelete);
            if (isLoggedIn && result) {
                AufnahmeformularService.load(dispatch);
            }
        }
    };

    return (
        <>
            <div className="AdminDashboardForms">
                <h2>Aufnahmeformulare</h2>
                {aufnahmeformulare.length === 0 ? (
                    <i>Keine Formulare vorhanden</i>
                ) : (
                    <></>
                )}
                <div>
                    {aufnahmeformulare.map((el) => (
                        <div
                            key={`key-aufnahmeformular-${el.id}`}
                            className="d-flex justify-content-between align-items-center bg-n200 rounded-4 px-3 py-2 mb-3"
                        >
                            <span>
                                {new Date(el.timestamp * 1000).toLocaleString()}
                            </span>
                            <div className="d-flex">
                                <SmallIconButton
                                    description="Formular herunterladen"
                                    onClick={() => onDownloadBtnClick(el)}
                                    Icon={DownloadIcon}
                                    primary={true}
                                    small={true}
                                ></SmallIconButton>
                                <div className="mx-1"></div>
                                <SmallIconButton
                                    description="Formular löschen"
                                    onClick={() => onDeleteBtnClick(el.id)}
                                    Icon={TrashIcon}
                                    danger={true}
                                    small={true}
                                ></SmallIconButton>
                            </div>
                        </div>
                    ))}
                </div>
                {showConfirmationModal ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie das Formular wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowConfirmationModal}
                        show={showConfirmationModal}
                        onCancel={() => setShowConfirmationModal(false)}
                        onConfirm={() => onConfirmDeleteClick()}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default AdminDashboardForms;
