import xss from "xss";
import { RequestMethod } from "../../enums/RequestMethod";
import RequestService from "../requestService/RequestService";

const CommonService = {
    convertAndCheckId: (id: string): number | null => {
        const xssId = xss(id);
        const convertedId = parseInt(xssId);

        if (isNaN(convertedId)) return null;

        return convertedId;
    },

    uploadDocument: async (file: File): Promise<string> => {
        let filepath: string = "";

        try {
            const formData = new FormData();
            formData.append("type", "document");
            formData.append("document", file);

            const response = await RequestService.makeRequest<{
                success: Boolean;
                filepath: string;
            }>(
                "/api/add/upload-document",
                RequestMethod.POST,
                undefined,
                formData,
                {}
            );

            if (
                "error" in response.data ||
                response.data.success === false ||
                response.data.filepath === ""
            ) {
                filepath = "";
            } else {
                filepath = response.data.filepath;
            }
        } catch (err) {
            console.error(err);
        }

        return filepath;
    },

    uploadArticleImage: async (file: File): Promise<string> => {
        let filepath: string = "";

        try {
            const formData = new FormData();
            formData.append("type", "article-image");
            formData.append("image", file);

            const response = await RequestService.makeRequest<{
                success: boolean;
                filepath: string;
            }>(
                "/api/add/upload-article-image",
                RequestMethod.POST,
                undefined,
                formData,
                {}
            );

            if (
                "error" in response.data ||
                response.data.success === false ||
                response.data.filepath === ""
            ) {
                filepath = "";
            } else {
                filepath = response.data.filepath;
            }
        } catch (err) {
            console.error(err);
        }

        return filepath;
    },

    deleteUploadArticleImage: async (filepath: string): Promise<boolean> => {
        let success: boolean = false;

        try {
            const body = { filepath };
            const response = await RequestService.makeRequest<{
                success: boolean;
            }>("/api/delete/upload-article-image", RequestMethod.DELETE, body);

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }

            return success;
        } catch (err) {
            console.log(err);
        }

        return success;
    },

    sendTestEmail: async (): Promise<boolean> => {
        let success: boolean = false;

        try {
            const response = await RequestService.makeRequest<{
                success: boolean;
            }>("/api/test-email-connection", RequestMethod.GET);

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }

            return success;
        } catch (err) {
            console.log(err);
        }

        return success;
    },
};

export default CommonService;
