import classNames from "classnames";
import { ButtonType } from "../../../../enums/ButtonType";

export interface IconButtonProps {
    title: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    > | null;
    primary: boolean;
    danger?: boolean;
    padding?: boolean;
    type?: ButtonType;
}

const IconButton = ({
    title,
    onClick,
    Icon,
    primary,
    danger = false,
    padding = false,
    type = ButtonType.Button,
}: IconButtonProps) => {
    return (
        <>
            <button
                className={classNames("IconButton", {
                    "IconButton--primary": primary,
                    "IconButton--danger": danger,
                    "IconButton--secondary": !primary && !danger,
                    "px-5": padding,
                })}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    onClick(event)
                }
                type={type}
            >
                <span className="l2-sb">{title}</span>
                {Icon ? <Icon className="IconButton__icon"></Icon> : <></>}
            </button>
        </>
    );
};

export default IconButton;
