import { useAppDispatch, useAppSelector } from "../../../../hooks";

import HorizontalLine from "../../../../components/common/horizontalLine/HorizontalLine";
import IconButton from "../../../../components/common/buttons/iconButton/IconButton";
import { ReactComponent as PaperPlaneIcon } from "../../../../assets/resources/icons/paper-plane.svg";
import CommonService from "../../../../services/commonService/CommonService";
import { alertAdded } from "../../../../features/alerts/alertsSlice";
import { AlertVariant } from "../../../../enums/AlertVariant";

const AdminDashboardMain = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user);
    const lastLogin = new Date(user.last_login * 1000).toLocaleString();

    const onSendTestEmailClick = async (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const success = await CommonService.sendTestEmail();

        if (success) {
            dispatch(
                alertAdded({
                    text: "E-Mail wurde versandt.",
                    variant: AlertVariant.Success,
                })
            );
        } else {
            dispatch(
                alertAdded({
                    text: "E-Mail konnte nicht gesendet werden.",
                    variant: AlertVariant.Danger,
                })
            );
        }
    };

    return (
        <>
            <div>
                <h2>Hallo {user.username}</h2>
                <p className="b1-sb">Letzter Login: {lastLogin}</p>
                <HorizontalLine></HorizontalLine>
                <IconButton
                    title="Testemail senden"
                    onClick={onSendTestEmailClick}
                    Icon={PaperPlaneIcon}
                    primary={true}
                ></IconButton>
            </div>
        </>
    );
};

export default AdminDashboardMain;
