import { RootState } from "./../../store";
import { RequestStatus } from "./../../enums/RequestStatus";
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityState,
} from "@reduxjs/toolkit";
import ISatzung from "../../interfaces/ISatzung";
import { SatzungenService } from "../../services/satzungenService/SatzungenService";

const satzungenAdapter = createEntityAdapter({
    sortComparer: (a: ISatzung, b: ISatzung) => b.timestamp - a.timestamp,
});

const initialState: {
    status: RequestStatus;
    error: string | null;
    satzungen: EntityState<ISatzung>;
} = {
    status: RequestStatus.Idle,
    error: null,
    satzungen: satzungenAdapter.getInitialState(),
};

export const fetchSatzungen = createAsyncThunk(
    "satzungen/fetchSatzungen",
    async () => await SatzungenService.get()
);

export const satzungenSlice = createSlice({
    name: "satzungen",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchSatzungen.pending, (state, _) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(fetchSatzungen.fulfilled, (state, action) => {
                state.status = RequestStatus.Succeeded;
                satzungenAdapter.upsertMany(state.satzungen, action.payload);
            })
            .addCase(fetchSatzungen.rejected, (state, action) => {
                state.status = RequestStatus.Failed;
                state.error = action.error.message ?? "Error";
            });
    },
});

export default satzungenSlice.reducer;

export const { selectAll: selectAllSatzungen } = satzungenAdapter.getSelectors(
    (state: RootState) => state.satzungen.satzungen
);
