import { RootState } from "./../../store";
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    EntityState,
} from "@reduxjs/toolkit";
import { RequestStatus } from "../../enums/RequestStatus";
import IEvent from "../../interfaces/IEvent";
import { NaechsteEventsService } from "../../services/naechsteEventsService/NaechsteEventsService";

const naechsteEventsAdapter = createEntityAdapter({
    sortComparer: (a: IEvent, b: IEvent) => a.date - b.date,
});

const initialState: {
    status: RequestStatus;
    error: string | null;
    naechsteEvents: EntityState<IEvent>;
} = {
    status: RequestStatus.Idle,
    error: null,
    naechsteEvents: naechsteEventsAdapter.getInitialState(),
};

export const fetchNaechsteEvents = createAsyncThunk(
    "naechsteEvents/fetchNaechsteEvents",
    async () => {
        const response = await NaechsteEventsService.get();

        const dateNow = Date.now();

        const result = response.filter((event) => event.date * 1000 >= dateNow);

        return result;
    }
);

export const naechsteEventsSlice = createSlice({
    name: "naechsteEvents",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchNaechsteEvents.pending, (state, _) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(fetchNaechsteEvents.fulfilled, (state, action) => {
                state.status = RequestStatus.Succeeded;
                naechsteEventsAdapter.upsertMany(
                    state.naechsteEvents,
                    action.payload
                );
            })
            .addCase(fetchNaechsteEvents.rejected, (state, action) => {
                state.status = RequestStatus.Failed;
                state.error = action.error.message ?? "Error";
            });
    },
});

export default naechsteEventsSlice.reducer;

export const { selectAll: selectAllNaechsteEvents } =
    naechsteEventsAdapter.getSelectors(
        (state: RootState) => state.naechsteEvents.naechsteEvents
    );
