import HCaptcha from "@hcaptcha/react-hcaptcha";

export interface HCaptchaComponentProps {
    setToken: (token: string) => void;
}

const HCaptchaComponent = ({ setToken }: HCaptchaComponentProps) => {
    const sitekey = process.env.REACT_APP_HCAPTCHA_SITEKEY;

    const handleVerificationSuccess = (token: string, ekey: string): void => {
        console.log("Completed Captcha");
        setToken(token);
    };

    return (
        <>
            {sitekey ? (
                <HCaptcha
                    sitekey={sitekey}
                    onVerify={(token: string, ekey: string) =>
                        handleVerificationSuccess(token, ekey)
                    }
                />
            ) : (
                <>
                    <span>Error: Captcha kann nicht geladen werden!</span>
                </>
            )}
        </>
    );
};

export default HCaptchaComponent;
