import classNames from "classnames";

export interface SmallIconButtonProps {
    description: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    > | null;
    small?: boolean;
    warning?: boolean;
    danger?: boolean;
    primary?: boolean;
}

const SmallIconButton = ({
    description,
    onClick,
    Icon,
    small = false,
    warning = false,
    danger = false,
    primary = false,
}: SmallIconButtonProps) => {
    return (
        <>
            <button
                className={classNames("SmallIconButton", {
                    "SmallIconButton--smaller": small,
                    "SmallIconButton--primary": primary,
                    "SmallIconButton--warning": warning,
                    "SmallIconButton--danger": danger,
                })}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    onClick(event)
                }
            >
                <span className="d-none">{description}</span>
                {Icon ? <Icon className="SmallIconButton__icon"></Icon> : <></>}
            </button>
        </>
    );
};

export default SmallIconButton;
