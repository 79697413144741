import { AppDispatch } from "./../../store";
import RequestService from "../requestService/RequestService";
import { RequestMethod } from "./../../enums/RequestMethod";
import IAnmeldeformular from "../../interfaces/IAnmeldeformular";
import { setAnmeldeformulare } from "../../features/admin/adminSlice";

const AufnahmeformularService = {
    send: async (file: File, token: string): Promise<boolean> => {
        let success: boolean = false;

        try {
            const formData = new FormData();
            formData.append("token", token);
            formData.append("type", "aufnahmeformular");
            formData.append("file", file);

            const response = await RequestService.makeRequest<{
                success: boolean;
            }>(
                "/api/add/aufnahmeformular",
                RequestMethod.POST,
                undefined,
                formData,
                {}
            );

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }
        } catch (err) {
            console.error(err);
        }

        return success;
    },

    load: async (dispatch: AppDispatch): Promise<void> => {
        try {
            const response = await RequestService.makeRequest<
                IAnmeldeformular[]
            >("/api/aufnahmeformulare", RequestMethod.GET);

            if ("error" in response.data) {
                throw new Error(
                    "Aufnahmeformulare konnten nicht geladen werden."
                );
            } else {
                const sorted = response.data.sort(
                    (a, b) => b.timestamp - a.timestamp
                );
                dispatch(setAnmeldeformulare(sorted));
            }
        } catch (err) {
            console.error(err);
        }
    },

    delete: async (id: number): Promise<boolean> => {
        let success: boolean = false;

        try {
            const body = {
                id,
            };
            const response = await RequestService.makeRequest<{
                success: Boolean;
            }>("/api/delete/aufnahmeformular", RequestMethod.DELETE, body);

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }
        } catch (err) {
            console.error(err);
        }

        return success;
    },
};

export default AufnahmeformularService;
