import { useEffect, useState } from "react";

import {
    fetchSatzungen,
    selectAllSatzungen,
} from "../../features/satzungen/satzungenSlice";
import { RequestStatus } from "../../enums/RequestStatus";
import { useAppDispatch, useAppSelector } from "../../hooks";

import ISatzung from "../../interfaces/ISatzung";
import SatzungenCard from "./components/satzungenCard/SatzungenCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userCanEditContent } from "../../features/user/userSlice";
import FloatingActionButton from "../../components/common/buttons/floatingActionButton/FloatingActionButton";

import { ReactComponent as PlusIcon } from "./../../assets/resources/icons/plus.svg";
import { ReactComponent as TrashIcon } from "./../../assets/resources/icons/trash.svg";
import { SatzungenService } from "../../services/satzungenService/SatzungenService";
import ConfirmationModal from "../../components/common/confirmationModal/ConfirmationModal";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { AlertVariant } from "../../enums/AlertVariant";

const Satzungen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userCanEdit = useSelector(userCanEditContent);

    const satzungen = useAppSelector((state) => selectAllSatzungen(state));

    const satzungenStatus = useAppSelector((state) => state.satzungen.status);
    const error = useAppSelector((state) => state.satzungen.error);

    useEffect(() => {
        if (error) {
            dispatch(
                alertAdded({
                    text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                    variant: AlertVariant.Danger,
                })
            );

            console.error(error);
        }
    }, [error]);

    const [satzungsIdToDelete, setSatzungsIdToDelete] = useState<number | null>(
        null
    );
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState<boolean>(false);

    const onAddNewSatzungBtnClick = (): void => {
        navigate("/admin/add/satzung");
    };

    const publishSatzung = async (id: number): Promise<void> => {
        const result = await SatzungenService.publish(id);
        if (result) dispatch(fetchSatzungen());
    };

    const deleteSatzung = (id: number): void => {
        setSatzungsIdToDelete(id);
        setShowDeleteConfirmationModal(true);
    };

    const onDeleteSatzungConfirmed = async (): Promise<void> => {
        if (satzungsIdToDelete === null) return;

        setShowDeleteConfirmationModal(false);

        const successful = await SatzungenService.delete(satzungsIdToDelete);

        if (successful) {
            dispatch(
                alertAdded({
                    text: "Satzung gelöscht",
                    variant: AlertVariant.Success,
                })
            );
            dispatch(fetchSatzungen());
        } else {
            dispatch(
                alertAdded({
                    text: "Satzung konnte nicht gelöscht werden",
                    variant: AlertVariant.Danger,
                })
            );
        }
    };

    useEffect((): void => {
        if (satzungenStatus === RequestStatus.Idle) {
            dispatch(fetchSatzungen());
        }
    }, [satzungenStatus, dispatch]);

    return (
        <>
            <div className="Satzungen mb-4">
                <h1 className="text-center">Satzungen</h1>
                <div className="row">
                    {satzungenStatus === RequestStatus.Succeeded &&
                    satzungen.length === 0 ? (
                        <div className="text-center">
                            <i>Keine Einträge vorhanden</i>
                        </div>
                    ) : (
                        <></>
                    )}
                    {satzungen.map((satzung: ISatzung) => {
                        if (userCanEdit === false && satzung.draft === 1) {
                            return <></>;
                        }

                        return (
                            <div
                                key={`key-satzung-${satzung.id}`}
                                className="col-12 col-md-6 col-xl-4"
                            >
                                <SatzungenCard
                                    satzung={satzung}
                                    publishSatzung={publishSatzung}
                                    deleteSatzung={deleteSatzung}
                                ></SatzungenCard>
                            </div>
                        );
                    })}
                </div>
                {userCanEdit ? (
                    <FloatingActionButton
                        description="Neuen Eintrag hinzufügen"
                        onClick={onAddNewSatzungBtnClick}
                        Icon={PlusIcon}
                    ></FloatingActionButton>
                ) : (
                    <></>
                )}
                {showDeleteConfirmationModal && userCanEdit ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie die Satzung wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowDeleteConfirmationModal}
                        show={showDeleteConfirmationModal}
                        onCancel={() => setShowDeleteConfirmationModal(false)}
                        onConfirm={onDeleteSatzungConfirmed}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Satzungen;
