import { RenderElementProps } from "slate-react";
import SmallIconButton from "../../../../../../../buttons/smallIconButton/SmallIconButton";

import { ReactComponent as CrossIcon } from "../../../../../../../../../assets/resources/icons/cross.svg";
import { ReactComponent as EditIcon } from "../../../../../../../../../assets/resources/icons/pencil.svg";
import { CustomElement } from "../../../../../../SlateEditorTypes";

export interface SlateEditorCustomImageProps extends RenderElementProps {
    readOnly: boolean;
    onImageDeleted: (image: CustomElement) => Promise<void>;
}

const SlateEditorCustomImage = ({
    attributes,
    element,
    children,
    readOnly,
    onImageDeleted,
}: SlateEditorCustomImageProps) => {
    const onDeleteBtnClick = async (event: React.MouseEvent): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        onImageDeleted(element);
    };

    return (
        <>
            <div className="SlateEditorCustomImage__container" {...attributes}>
                {children}
                <div
                    contentEditable={false}
                    className="SlateEditorCustomImage__inner-container"
                >
                    <img
                        className="SlateEditorCustomImage__img"
                        src={element.imgUrl}
                        alt={element.imgAlt}
                    />
                    {!readOnly ? (
                        <div className="SlateEditorCustomImage__edit">
                            <SmallIconButton
                                description="Bild löschen"
                                onClick={onDeleteBtnClick}
                                Icon={CrossIcon}
                                small={true}
                                danger={true}
                            ></SmallIconButton>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <span
                    className="SlateEditorCustomImage__heading b2-r"
                    contentEditable={false}
                >
                    {element.imgHeading}
                </span>
            </div>
        </>
    );
};

export default SlateEditorCustomImage;
