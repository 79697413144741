export interface TextareaProps {
    id: string;
    rows: number;
    placeholder: string;
    required: boolean;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    value?: any;
    elementRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
}

const Textarea = ({
    id,
    rows,
    placeholder,
    required,
    onChange,
    value = undefined,
    elementRef,
}: TextareaProps) => {
    return (
        <>
            <div className="mb-3 w-100">
                <label className="form-label l2-sb mb-2" htmlFor={id}>
                    {placeholder}
                </label>
                <textarea
                    ref={elementRef}
                    className="form-control w-100 bg-n100"
                    id={id}
                    rows={rows}
                    placeholder={placeholder}
                    name={id}
                    onChange={onChange}
                    required={required}
                    value={value}
                ></textarea>
            </div>
        </>
    );
};

export default Textarea;
