import IArticleImageAdd from "../../../../../interfaces/IArticleImageAdd";
import EditImagePreviewCard from "./components/editImagePreviewCard/EditImagePreviewCard";

export interface EditImagePreviewProps {
    images: IArticleImageAdd[];
    setImages: Function;
}

const EditImagePreview = ({ images, setImages }: EditImagePreviewProps) => {
    return (
        <>
            <div className="EditImagePreview w-100">
                {images.map((_, index) => (
                    <EditImagePreviewCard
                        key={`key-edit-image-preview-${index}`}
                        index={index}
                        images={images}
                        setImages={setImages}
                    ></EditImagePreviewCard>
                ))}
            </div>
        </>
    );
};

export default EditImagePreview;
