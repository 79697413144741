import { useEffect, useState } from "react";
import DropzoneComponent from "../../../../common/forms/dropzoneComponent/DropzoneComponent";
import { ReactComponent as ImageIcon } from "../../../../../assets/resources/icons/image.svg";
import IconButton from "../../../../common/buttons/iconButton/IconButton";
import IArticleImageAdd from "../../../../../interfaces/IArticleImageAdd";

export interface EditImageDropzoneProps {
    images: IArticleImageAdd[];
    setImages: Function;
}

const EditImageDropzone = ({ images, setImages }: EditImageDropzoneProps) => {
    const [files, setFiles] = useState<(File & { preview?: string })[]>([]);

    useEffect(() => {
        // Move images to parent component
        if (files.length > 0) {
            const newImages: IArticleImageAdd[] = [];
            files.forEach((file) => {
                newImages.push({
                    image: file,
                    image_alt: "",
                    image_subtitle: "",
                });
            });
            setImages([...images, ...newImages]);
            setFiles([]);
        }
    }, [files]);

    return (
        <>
            <DropzoneComponent
                accept={{ "image/*": [] }}
                multiple={true}
                files={files}
                setFiles={setFiles}
                preview={false}
            >
                <div className="w-100 h-100 d-flex justify-content-center align-items-center py-4">
                    <div className="w-50">
                        <ImageIcon
                            className="ic-b700 d-block mx-auto"
                            height="3rem"
                            width="auto"
                        ></ImageIcon>
                        <p className="b1-sb text-center mt-3">
                            Galerie-Bilder hierher ziehen zum hochladen
                        </p>
                        <div className="d-flex align-items-center my-4">
                            <div className="custom-hr"></div>
                            <span className="tc-n300 mx-3">ODER</span>
                            <div className="custom-hr"></div>
                        </div>
                        <IconButton
                            title="Dateien durchsuchen"
                            onClick={() => {}}
                            Icon={null}
                            primary={true}
                        ></IconButton>
                    </div>
                </div>
            </DropzoneComponent>
        </>
    );
};

export default EditImageDropzone;
