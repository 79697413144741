import { useCallback } from "react";
import { Editable, RenderElementProps, RenderLeafProps } from "slate-react";
import { SlateEditorLeaf } from "./components/slateEditorLeaf/SlateEditorLeaf";
import SlateEditorCustomElement from "./components/slateEditorCustomElement/SlateEditorCustomElement";
import classNames from "classnames";
import { CustomElement } from "../../SlateEditorTypes";

export interface SlateEditableProps {
    readOnly: boolean;
    onImageDeleted: (image: CustomElement) => Promise<void>;
}

const SlateEditable = ({ readOnly, onImageDeleted }: SlateEditableProps) => {
    const renderElement = useCallback(
        (props: RenderElementProps) => (
            <SlateEditorCustomElement
                {...props}
                readOnly={readOnly}
                onImageDeleted={onImageDeleted}
            ></SlateEditorCustomElement>
        ),
        []
    );

    const renderLeaf = useCallback(
        (props: RenderLeafProps) => <SlateEditorLeaf {...props} />,
        []
    );

    const onKeyDown = (event: React.KeyboardEvent) => {
        // TODO: Implement keyboard shortcuts
    };

    return (
        <>
            <Editable
                className={classNames({
                    "SlateEditable--modify": readOnly === false,
                    "SlateEditable--readOnly": readOnly === false,
                })}
                readOnly={readOnly}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={onKeyDown}
            />
        </>
    );
};

export default SlateEditable;
