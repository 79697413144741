import { RequestMethod } from "../../enums/RequestMethod";
import RequestService from "../requestService/RequestService";

export abstract class DatabaseDataService {
    protected static urlName: string = "";
    protected static friendlyName: string = "";
    protected static friendlyNamePlural: string = "";

    private static _propertiesAreValid(): boolean {
        return (
            this.urlName !== "" &&
            this.friendlyName !== "" &&
            this.friendlyNamePlural !== ""
        );
    }

    protected static async _get<T>(): Promise<T[]> {
        if (!this._propertiesAreValid()) {
            throw new Error("Properties of DatabaseDataService are not valid!");
        }

        try {
            const response = await RequestService.makeRequest<T[]>(
                `/api/${this.urlName}`,
                RequestMethod.GET
            );

            if ("error" in response.data) {
                throw new Error(
                    `${this.friendlyNamePlural} konnten nicht geladen werden.`
                );
            } else {
                return response.data;
            }
        } catch (err) {
            console.error(err);
            return [];
        }
    }

    protected static async _add<T>(data: T): Promise<boolean> {
        if (!this._propertiesAreValid()) {
            throw new Error("Properties of DatabaseDataService are not valid!");
        }

        let success: boolean = false;

        try {
            const requestResponse = await RequestService.makeRequest<{
                success: Boolean;
                id: Number;
            }>(`/api/add/${this.urlName}`, RequestMethod.POST, data);

            if (
                "error" in requestResponse.data ||
                requestResponse.data.success === false
            ) {
                throw new Error(
                    `${this.friendlyName} konnte nicht hinzugefügt werden.`
                );
            }

            success = true;
        } catch (err) {
            console.log(err);
        }

        return success;
    }

    protected static async _publish(id: number): Promise<boolean> {
        if (!this._propertiesAreValid()) {
            throw new Error("Properties of DatabaseDataService are not valid!");
        }

        let success: boolean = false;

        try {
            const body = {
                id,
            };

            const response = await RequestService.makeRequest<{
                success: Boolean;
            }>(`/api/publish/${this.urlName}`, RequestMethod.PUT, body);

            if ("error" in response.data || response.data.success === false) {
                throw new Error(
                    `${this.friendlyName} konnte nicht veröffentlicht werden.`
                );
            }

            success = true;
        } catch (err) {
            console.error(err);
        }

        return success;
    }

    protected static async _delete(id: number): Promise<boolean> {
        if (!this._propertiesAreValid()) {
            throw new Error("Properties of DatabaseDataService are not valid!");
        }

        let success: boolean = false;

        try {
            const body = {
                id,
            };

            const response = await RequestService.makeRequest<{
                success: Boolean;
            }>(`/api/delete/${this.urlName}`, RequestMethod.DELETE, body);

            if ("error" in response.data || response.data.success === false) {
                throw new Error(
                    `${this.friendlyName} konnte nicht gelöscht werden.`
                );
            }

            success = true;
        } catch (err) {
            console.error(err);
        }

        return success;
    }
}
