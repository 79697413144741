export interface StartseiteHeaderCardProps {
    title: string;
    text: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const StartseiteHeaderCard = ({
    title,
    text,
    Icon,
}: StartseiteHeaderCardProps) => {
    return (
        <>
            <div className="StartseiteHeaderCard">
                <div className="StartseiteHeaderCard__icon-container bg-b700 center-content shadow">
                    <Icon className="StartseiteHeaderCard__icon"></Icon>
                </div>
                <h3>{title}</h3>
                <p className="text-wrap">{text}</p>
            </div>
        </>
    );
};

export default StartseiteHeaderCard;
