import { Carousel } from "react-bootstrap";
import IArticleImage from "../../../interfaces/IArticleImage";

export interface ImageSliderProps {
    images: IArticleImage[];
}

const ImageSlider = ({ images }: ImageSliderProps) => {
    return (
        <>
            <div className="ImageSlider">
                <Carousel className="h-100 w-100">
                    {images.map((image) => (
                        <Carousel.Item
                            key={`key-image-slider-${image.id}`}
                            className="h-100 w-100"
                        >
                            <img
                                className="d-block w-100"
                                src={image.image}
                                alt={image.image_alt}
                            />
                            <Carousel.Caption>
                                <p>{image.image_subtitle}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </>
    );
};

export default ImageSlider;
