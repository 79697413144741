import { ReactComponent as HeartIcon } from "../../../../assets/resources/icons/heart.svg";
import { ButtonType } from "../../../../enums/ButtonType";

export interface DonateButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DonateButton = ({ onClick }: DonateButtonProps) => {
  return (
    <>
      <button
        className="DonateButton"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => onClick(event)}
        type={ButtonType.Button}
      >
        <div className="DonateButton__leftSide">
          <HeartIcon className="DonateButton__leftSide__icon"></HeartIcon>
        </div>
        <div className="DonateButton__rightSide">
          <span className="l1-sb">Spenden</span>
        </div>
      </button>
    </>
  );
};

export default DonateButton;
