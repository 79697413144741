import xss from "xss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";

import {
    fetchNaechsteEvents,
    selectAllNaechsteEvents,
} from "../../features/naechsteEvents/naechsteEventsSlice";
import { RequestStatus } from "../../enums/RequestStatus";
import { AnkuendigungenService } from "../../services/ankuendigungenService/AnkuendigungenService";

import VerticalLine from "../../components/common/verticalLine/VerticalLine";
import IconButton from "../../components/common/buttons/iconButton/IconButton";
import HorizontalLine from "../../components/common/horizontalLine/HorizontalLine";
import InlineButton from "../../components/common/buttons/inlineButton/InlineButton";
import StartseiteCardSection from "./components/StartseiteCardSection/StartseiteCardSection";
import StartseiteNextEventsSlider from "./components/StartseiteNextEventsSlider/StartseiteNextEventsSlider";

import schoolImage from "./../../assets/resources/images/schule.png";
import { ReactComponent as BoyVectorImage } from "./../../assets/resources/images/boy.svg";
import { ReactComponent as GirlVectorImage } from "./../../assets/resources/images/girl.svg";
import { ReactComponent as ArrowRight } from "./../../assets/resources/icons/arrow-right.svg";
import { ReactComponent as MegaphoneIcon } from "./../../assets/resources/icons/megaphone.svg";
import { ReactComponent as CalendarLinesIcon } from "./../../assets/resources/icons/calendar-lines.svg";
import { ReactComponent as PlusIcon } from "./../../assets/resources/icons/plus.svg";
import { ReactComponent as TrashIcon } from "./../../assets/resources/icons/trash.svg";
import { useSelector } from "react-redux";
import { userCanEditContent } from "../../features/user/userSlice";
import FloatingActionButton from "../../components/common/buttons/floatingActionButton/FloatingActionButton";
import { NaechsteEventsService } from "../../services/naechsteEventsService/NaechsteEventsService";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { AlertVariant } from "../../enums/AlertVariant";
import ConfirmationModal from "../../components/common/confirmationModal/ConfirmationModal";
import IEvent from "../../interfaces/IEvent";
import StartseiteNextEventsModal from "./components/StartseiteNextEventsModal/StartseiteNextEventsModal";

const Startseite = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userCanEdit = useSelector(userCanEditContent);

    const newestAnkuendigung = useAppSelector(
        (state) => state.ankuendigungen.newestAnkuendigung
    );

    const naechsteEvents = useAppSelector((state) =>
        selectAllNaechsteEvents(state)
    );

    const naechsteEventsStatus = useAppSelector(
        (state) => state.naechsteEvents.status
    );
    const naechsteEventsError = useAppSelector(
        (state) => state.naechsteEvents.error
    );

    useEffect(() => {
        if (naechsteEventsError) {
            dispatch(
                alertAdded({
                    text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                    variant: AlertVariant.Danger,
                })
            );

            console.error(naechsteEventsError);
        }
    }, [naechsteEventsError]);

    const [eventIdToDelete, setEventIdToDelete] = useState<number | null>(null);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState<boolean>(false);

    const [eventForModal, setEventForModal] = useState<IEvent | null>(null);
    const [showEventsModal, setShowEventsModal] = useState<boolean>(false);

    const openEventModal = (event: IEvent): void => {
        setEventForModal(event);
        setShowEventsModal(true);
    };

    const onJoinBtnClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        navigate("/beitritt");
    };

    const publishEvent = async (id: number): Promise<void> => {
        const result = await NaechsteEventsService.publish(id);
        if (result) dispatch(fetchNaechsteEvents());
    };

    const deleteEvent = async (id: number): Promise<void> => {
        setEventIdToDelete(id);
        setShowDeleteConfirmationModal(true);
    };

    const onDeleteSatzungConfirmed = async (): Promise<void> => {
        if (eventIdToDelete === null) return;

        setShowDeleteConfirmationModal(false);

        const successful = await NaechsteEventsService.delete(eventIdToDelete);

        if (successful) {
            dispatch(
                alertAdded({
                    text: "Event gelöscht",
                    variant: AlertVariant.Success,
                })
            );
            dispatch(fetchNaechsteEvents());
        } else {
            dispatch(
                alertAdded({
                    text: "Event konnte nicht gelöscht werden",
                    variant: AlertVariant.Danger,
                })
            );
        }
    };

    useEffect((): void => {
        AnkuendigungenService.loadNewest(dispatch);

        if (naechsteEventsStatus === RequestStatus.Idle) {
            dispatch(fetchNaechsteEvents());
        }
    }, [dispatch, naechsteEventsStatus]);

    const onContinueReadingBtnClick = () => {
        if (newestAnkuendigung) {
            navigate(`/ankuendigungen/${xss(newestAnkuendigung.id + "")}`);
        }
    };

    const onAddNewEventBtnClick = () => navigate("/admin/add/ereignis");

    return (
        <>
            <div className="Startseite">
                <header className="Startseite__header">
                    <div className="row">
                        <div className="col-12 col-lg-7 col-xxl-6 d-flex align-items-center">
                            <div>
                                <h1 className="Startseite__title title tc-b700">
                                    Förderverein der Grundschule Otterwisch
                                </h1>
                                <div className="Startseite__header__mobileImg d-lg-none mx-auto mb-5">
                                    <img
                                        className="d-block w-100 h-auto"
                                        src={schoolImage}
                                        alt="Bild der Vorderseite der Schule"
                                    />
                                </div>
                                <div className="me-5">
                                    <div className="mb-2rem">
                                        <p className="b0-sb mb-2">
                                            "Ein Kind ist kein Gefäß, das
                                            gefüllt, sondern ein Feuer, das
                                            entzündet werden will."
                                        </p>
                                        <span className="b3-r tc-n700">
                                            François Rabelais
                                        </span>
                                    </div>
                                    <p>
                                        Willkommen auf der Internetseite des
                                        Fördervereins der Grundschule
                                        Otterwisch.
                                        <br /> Hier erfahren Sie alle
                                        Neuigkeiten und Ankündigungen rund um
                                        den Verein.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-5 col-xxl-6 d-flex align-items-center h-100">
                            <img
                                className="d-block w-100 h-auto"
                                src={schoolImage}
                                alt="Bild der Vorderseite der Schule"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <StartseiteCardSection></StartseiteCardSection>
                    </div>
                </header>
                <section className="Startseite__announcements tc-n100">
                    <div className="row h-100">
                        <div className="col-12 col-xl-5">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="me-3 ic-n100 d-inline-block">
                                            <MegaphoneIcon
                                                height="1.8125rem"
                                                width="1.8125rem"
                                            ></MegaphoneIcon>
                                        </div>
                                        <h3>
                                            {newestAnkuendigung?.title ??
                                                "Neuste Ankündigung"}
                                        </h3>
                                    </div>
                                    <p>
                                        {newestAnkuendigung ? (
                                            newestAnkuendigung.preview_text
                                        ) : (
                                            <i className="text-center d-inline-block w-100 mt-5">
                                                Es gibt im Moment keine
                                                Ankündigungen.
                                            </i>
                                        )}
                                    </p>
                                </div>
                                {newestAnkuendigung ? (
                                    <div className="width-fit-content">
                                        <IconButton
                                            title="Weiterlesen"
                                            onClick={onContinueReadingBtnClick}
                                            Icon={ArrowRight}
                                            primary={false}
                                            padding={true}
                                        ></IconButton>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="d-none d-lg-flex col-xl-2 justify-content-center">
                            <VerticalLine dark={false}></VerticalLine>
                        </div>
                        <div className="mt-4 mb-2 d-xl-none">
                            <HorizontalLine></HorizontalLine>
                        </div>
                        <div className="col-12 col-xl-5">
                            <div className="d-flex align-items-center">
                                <div className="me-3 ic-n100 d-inline-block">
                                    <CalendarLinesIcon
                                        height="1.8125rem"
                                        width="1.8125rem"
                                    ></CalendarLinesIcon>
                                </div>
                                <h3>Nächste Ereignisse</h3>
                            </div>
                            {naechsteEvents.length > 0 ? (
                                <StartseiteNextEventsSlider
                                    events={naechsteEvents}
                                    publishEvent={publishEvent}
                                    deleteEvent={deleteEvent}
                                    openEventModal={openEventModal}
                                ></StartseiteNextEventsSlider>
                            ) : (
                                <>
                                    <i className="text-center d-inline-block w-100 mt-5">
                                        Es gibt im Moment keine Ereignisse.
                                    </i>
                                </>
                            )}
                        </div>
                    </div>
                </section>
                <div className="Startseite__announcements-bg"></div>
                <section className="my-5 py-5">
                    <div className="row">
                        <div className="col-4 col-xl-3 clearfix">
                            <div className="Startseite__children-image Startseite__children-image--boy">
                                <BoyVectorImage></BoyVectorImage>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-xl-7 col-xxxl-5 d-flex align-items-center">
                            <div className="w-100">
                                <h3>Was ist unser Ziel?</h3>
                                <p>
                                    Der Verein will die Schule dort finanziell
                                    unterstützen, wo die staatlichen Mittel
                                    nicht ausreichen oder fehlen. Wir wollen
                                    zusätzliche Angebote für die Kinder an der
                                    Grundschule Otterwisch ermöglichen und
                                    bestehende Angebote erhalten. Dafür bitten
                                    wir Sie um Unterstützung zu Gunsten der
                                    Schülerinnen und Schüler.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-2 col-xxxl-4"></div>
                    </div>
                    <div className="row d-md-none">
                        <div className="col-8"></div>
                        <div className="col-4 col-xl-3 clearfix">
                            <div className="Startseite__children-image Startseite__children-image--girl">
                                <GirlVectorImage></GirlVectorImage>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-2 col-xxxl-4"></div>
                        <div className="col-12 col-md-8 col-xl-7 col-xxxl-5 d-flex align-items-center">
                            <div className="w-100 d-flex flex-column align-items-end">
                                <h3>Wir benötigen jeden!</h3>
                                <p>
                                    Bitte unterstützen Sie Ihr Kind, indem Sie
                                    uns unterstützen! Sei es als{" "}
                                    <InlineButton
                                        text="Mitglied"
                                        to="/beitritt"
                                        staticUnderline={true}
                                    ></InlineButton>{" "}
                                    (7,- € im Jahr) oder als{" "}
                                    <InlineButton
                                        text="Spender"
                                        to="/spenden"
                                        staticUnderline={true}
                                    ></InlineButton>
                                    . Auch kleine Spenden können Wunder wirken.
                                </p>
                                <p>
                                    Wir würden uns sehr freuen, Sie als
                                    Mitglieder unseres Fördervereins begrüßen zu
                                    dürfen. Liebe Eltern - bedenken Sie bitte
                                    auch, dass sie über die Plattform des
                                    Fördervereins zusätzliche Informationen über
                                    geplante Schulprojekte erhalten und andere
                                    Eltern näher kennen lernen.
                                </p>
                                <div className="width-fit-content mt-4">
                                    <IconButton
                                        title="Jetzt beitreten"
                                        onClick={onJoinBtnClick}
                                        Icon={null}
                                        primary={true}
                                        padding={true}
                                    ></IconButton>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-4 col-xl-3 clearfix">
                            <div className="Startseite__children-image Startseite__children-image--girl">
                                <GirlVectorImage></GirlVectorImage>
                            </div>
                        </div>
                    </div>
                </section>
                {userCanEdit ? (
                    <FloatingActionButton
                        description="Neuen Eintrag hinzufügen"
                        onClick={onAddNewEventBtnClick}
                        Icon={PlusIcon}
                    ></FloatingActionButton>
                ) : (
                    <></>
                )}
                {showDeleteConfirmationModal && userCanEdit ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie die Satzung wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowDeleteConfirmationModal}
                        show={showDeleteConfirmationModal}
                        onCancel={() => setShowDeleteConfirmationModal(false)}
                        onConfirm={onDeleteSatzungConfirmed}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
                {showEventsModal ? (
                    <StartseiteNextEventsModal
                        event={eventForModal}
                        setShow={setShowEventsModal}
                    ></StartseiteNextEventsModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Startseite;
