import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopAfterRouteChange = () => {
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
        });
    }, [location.pathname]);

    return null;
};

export default ScrollToTopAfterRouteChange;
