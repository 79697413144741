export enum EditPageOptionDataFieldType {
    Number,
    String,
    Password,
    Text,
    RichText,
    Date,
    ImageDropzone,
    ImagePreview,
    DocumentDropzone,
    Checkbox,
}
