import classNames from "classnames";
import {
    userCanEditContent,
    userIsLoggedIn,
} from "../../../features/user/userSlice";
import { useAppSelector } from "../../../hooks";
import IArticle from "../../../interfaces/IArticle";
import ContentActionButtons from "../../admin/contentActionButtons/ContentActionButtons";

export interface ArticleCardProps {
    article: IArticle;
    onClick: () => void;
    publishArticle: (id: number) => void;
    deleteArticle: (id: number) => void;
}

const ArticleCard = ({
    article,
    onClick,
    publishArticle,
    deleteArticle,
}: ArticleCardProps) => {
    const isLoggedIn = useAppSelector(userIsLoggedIn);
    const canEdit = useAppSelector(userCanEditContent);

    const uploadDate = new Date(article.timestamp * 1000);
    const publishedInLast3Days =
        article.timestamp > Date.now() / 1000 - 60 * 60 * 24 * 3;

    // TODO MAKE PLACEHOLDER RESPONSIVE

    const onPublishClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        publishArticle(article.id);
    };

    return (
        <>
            <div
                className={classNames("ArticleCard", {
                    "ArticleCard--draft": article.draft === 1,
                })}
                onClick={onClick}
            >
                <div className="ArticleCard__img-container placeholder-glow position-relative">
                    <img
                        src={article.title_image}
                        alt={article.title_image_alt}
                    />
                    <div className="placeholder w-100 h-100 position-absolute top-0 start-0 z-index--1 rounded"></div>
                </div>
                <div className="ArticleCard__info">
                    <div className="ArticleCard__info-text">
                        <div className="ArticleCard__attentionText l2-sb tc-r700">
                            <span>
                                {publishedInLast3Days ? (
                                    <>
                                        In den letzten drei Tagen
                                        veröffentlicht!
                                    </>
                                ) : (
                                    <></>
                                )}
                            </span>
                        </div>
                        <h3 className="ArticleCard__heading">
                            {article.title}
                        </h3>
                        <span className="ArticleCard__preview-text">
                            {article.preview_text}
                        </span>
                    </div>
                    <div className="l2-r tc-n500">
                        <span>
                            {article.author} · {uploadDate.toLocaleString()} ·{" "}
                            {article.reading_time} min Lesezeit
                        </span>
                    </div>
                </div>
                {isLoggedIn && canEdit ? (
                    <ContentActionButtons
                        onEditClick={(e) => {}}
                        onDeleteClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            deleteArticle(article.id);
                        }}
                        onPublishClick={
                            article.draft === 1 ? onPublishClick : null
                        }
                    ></ContentActionButtons>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default ArticleCard;
