import { alertRemoved } from "../../../features/alerts/alertsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import IAlert from "../../../interfaces/IAlert";
import CustomAlert from "./components/customAlert/CustomAlert";

const AlertContainer = () => {
    const dispatch = useAppDispatch();

    const alerts = useAppSelector((state) => state.alerts.alerts);

    const onRemoveAlertClick = (alert: IAlert): void => {
        dispatch(alertRemoved(alert));
    };

    return (
        <>
            <div className="AlertContainer">
                {alerts.map((alert, index) => (
                    <CustomAlert
                        key={`key-alerts-${index}`}
                        alert={alert}
                        onClose={onRemoveAlertClick}
                    ></CustomAlert>
                ))}
            </div>
        </>
    );
};

export default AlertContainer;
