import ankuendigungenSlice from "./features/ankuendigungen/ankuendigungenSlice";
import { configureStore } from "@reduxjs/toolkit";
import projekteSlice from "./features/projekte/projekteSlice";
import satzungenSlice from "./features/satzungen/satzungenSlice";
import userSlice from "./features/user/userSlice";
import adminSlice from "./features/admin/adminSlice";
import alertsSlice from "./features/alerts/alertsSlice";
import naechsteEventsSlice from "./features/naechsteEvents/naechsteEventsSlice";

const store = configureStore({
    reducer: {
        projekte: projekteSlice,
        ankuendigungen: ankuendigungenSlice,
        satzungen: satzungenSlice,
        user: userSlice,
        admin: adminSlice,
        alerts: alertsSlice,
        naechsteEvents: naechsteEventsSlice,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
