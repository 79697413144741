import { useSelector } from "react-redux";
import { userIsLoggedIn } from "../../features/user/userSlice";
import InlineButton from "../common/buttons/inlineButton/InlineButton";

const Footer = () => {
    const isLoggedIn = useSelector(userIsLoggedIn);

    return (
        <>
            <div className="container bg-b700 h-100">
                <footer className="Footer tc-n100 bg-b700 h-100">
                    <div className="row h-100">
                        <div className="Footer-items col-12 l3-r">
                            <span>
                                Copyright © {new Date().getFullYear()}{" "}
                                Förderverein Grundschule Otterwisch e.V. All
                                rights reserved.
                            </span>
                            <InlineButton
                                text="Datenschutzerklärung"
                                to="/datenschutzerklaerung"
                                light={true}
                            ></InlineButton>
                            <InlineButton
                                text="Impressum"
                                to="/impressum"
                                light={true}
                            ></InlineButton>
                            {isLoggedIn === true ? (
                                <InlineButton
                                    text="Admin-Dashboard"
                                    to="/admin/dashboard"
                                    light={true}
                                ></InlineButton>
                            ) : (
                                <InlineButton
                                    text="Admin Login"
                                    to="/admin"
                                    light={true}
                                ></InlineButton>
                            )}
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default Footer;
