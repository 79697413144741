import { AppDispatch } from "./../../store";
import { RequestMethod } from "../../enums/RequestMethod";
import IContactSend from "../../interfaces/IContactSend";
import RequestService from "../requestService/RequestService";
import IKontaktanfrage from "../../interfaces/IKontaktanfrage";
import { setKontaktanfragen } from "../../features/admin/adminSlice";

const KontaktService = {
    send: async (data: IContactSend): Promise<boolean> => {
        let success: boolean = false;

        try {
            const response = await RequestService.makeRequest<{
                success: Boolean;
            }>("/api/add/kontaktanfrage", RequestMethod.POST, data);

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }
        } catch (err) {
            console.log(err);
        }

        return success;
    },

    load: async (dispatch: AppDispatch): Promise<void> => {
        try {
            const response = await RequestService.makeRequest<
                IKontaktanfrage[]
            >("/api/kontaktanfragen", RequestMethod.GET);

            if ("error" in response.data) {
                throw new Error(
                    "Kontaktanfragen konnten nicht geladen werden."
                );
            } else {
                const sorted = response.data.sort(
                    (a, b) => b.timestamp - a.timestamp
                );
                dispatch(setKontaktanfragen(sorted));
            }
        } catch (err) {
            console.error(err);
        }
    },

    delete: async (id: number): Promise<boolean> => {
        let success: boolean = false;

        try {
            const body = {
                id,
            };
            const response = await RequestService.makeRequest<{
                success: Boolean;
            }>("/api/delete/kontaktanfrage", RequestMethod.DELETE, body);

            if ("error" in response.data || response.data.success === false) {
                success = false;
            } else {
                success = true;
            }
        } catch (err) {
            console.error(err);
        }

        return success;
    },
};

export default KontaktService;
