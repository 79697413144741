import { useEffect, useState } from "react";
import DonateButton from "../../components/common/buttons/donateButton/DonateButton";
import InlineButton from "../../components/common/buttons/inlineButton/InlineButton";

import confetti from "canvas-confetti";
import DonationCompletedModal from "./components/donationCompletedModal/DonationCompletedModal";
import { useLocation } from "react-router-dom";

const Spenden = () => {
  const location = useLocation();
  const [showDonationCompletedModal, setShowDonationCompletedModal] =
    useState<boolean>(false);

  const confettiDuration = 7 * 1000;

  function fireRealisticConfetti(
    particleCount: number,
    angle: number,
    x: number,
    y: number
  ) {
    confetti({
      particleCount: Math.floor(particleCount * 0.25),
      angle,
      origin: { x, y },
      spread: 26,
      startVelocity: 55,
    });
    confetti({
      particleCount: Math.floor(particleCount * 0.2),
      angle,
      origin: { x, y },
      spread: 60,
    });
    confetti({
      particleCount: Math.floor(particleCount * 0.35),
      angle,
      origin: { x, y },
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    confetti({
      particleCount: Math.floor(particleCount * 0.1),
      angle,
      origin: { x, y },
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    confetti({
      particleCount: Math.floor(particleCount * 0.1),
      angle,
      origin: { x, y },
      spread: 120,
      startVelocity: 45,
    });
  }

  const openDonateLink = () => {
    window.open(
      "https://www.paypal.com/donate/?hosted_button_id=ARHJMPZWVSA4C",
      "_self"
    );
  };

  useEffect(() => {
    const donationIsCompleted = location.pathname === "/spenden/completed";

    setShowDonationCompletedModal(donationIsCompleted);

    if (donationIsCompleted) {
      const confettiEnd = Date.now() + confettiDuration;
      const confettiInterval = setInterval(() => {
        const timeLeft = confettiEnd - Date.now();
        if (timeLeft <= 0) {
          clearInterval(confettiInterval);
          return;
        }

        const particleCount = 200 * (timeLeft / confettiDuration);

        fireRealisticConfetti(particleCount, 45, 0.0, 0.3);
        fireRealisticConfetti(particleCount, 135, 1.0, 0.3);
      }, 1000);
    }
  }, [location]);

  return (
    <>
      <div className="Spenden mb-5">
        <div className="row">
          <div className="d-none d-md-block col-md-3"></div>
          <div className="col-12 col-md-6 text-center">
            <h1>Spenden</h1>
            <p>
              Sie wollen uns unterstützen?
              <br />
              Über den Spenden-Button gelangen Sie direkt zu unserer Paypal
              Spendenaktion.
              <br /><br />
              Alternativ finden Sie weiter unten alle nötigen Kontaktdaten für
              eine Überweisung oder unsere E-Mail-Adresse von Paypal für eine
              manuelle Transaktion.
              <br /><br />
              Gern senden wir Ihnen eine Spendenquittung zu. Bitte schicken Sie
              uns dafür eine E-Mail oder verwenden Sie das{" "}
              <InlineButton
                text="Kontaktformular"
                to="/kontakt"
                staticUnderline={true}
              ></InlineButton>
              .
            </p>
            <div className="my-5">
              <DonateButton onClick={openDonateLink}></DonateButton>
            </div>
            <h2>Paypal</h2>
            <strong>foerderverein.gs.otterwisch@gmx.de</strong>
            <h2>Bankverbindung</h2>
            <strong>DE16 860 502 00 101 00 42374</strong>
            <br />
            <strong>Sparkasse Muldental</strong>
            <br />
            <strong>Steuernummer: 238/140/07391</strong>
          </div>
          <div className="d-none d-md-block col-md-3"></div>
        </div>
        {showDonationCompletedModal ? (
          <DonationCompletedModal
            setShow={setShowDonationCompletedModal}
          ></DonationCompletedModal>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Spenden;
