import { RequestMethod } from "../../enums/RequestMethod";
import IRequest from "../../interfaces/IRequest";

const RequestService = {
  makeRequest: async <T>(
    url: string,
    method: RequestMethod,
    body?: any,
    formData?: FormData,
    customHeaders?: {}
  ): Promise<IRequest<T>> => {
    const headers = customHeaders
      ? customHeaders
      : {
          "Content-Type": "application/json",
        };

    let configBody = null;
    if (body) {
      configBody = JSON.stringify(body);
    } else if (formData) {
      configBody = formData;
    }

    const config: RequestInit = {
      method,
      credentials: "include",
      headers,
      body: configBody,
    };

    try {
      const res = await fetch(url, config);
      const data = await res.json();
      if (res.ok) {
        return {
          status: res.status,
          data,
          headers: res.headers,
          url: res.url,
        };
      }
      throw new Error(`Error getting data from backend: ${res.statusText}`);
    } catch (err: any) {
      return Promise.reject(err.message ? err.message : "Error");
    }
  },
};

export default RequestService;
