import classNames from "classnames";
import IEvent from "../../../../../../interfaces/IEvent";
import ContentActionButtons from "../../../../../../components/admin/contentActionButtons/ContentActionButtons";
import {
    userCanEditContent,
    userIsLoggedIn,
} from "../../../../../../features/user/userSlice";
import { useAppSelector } from "../../../../../../hooks";

export interface StartseiteNextEventCardProps {
    event: IEvent;
    publishEvent: (id: number) => void;
    deleteEvent: (id: number) => void;
    openEventModal: (event: IEvent) => void;
}

const StartseiteNextEventCard = ({
    event,
    publishEvent,
    deleteEvent,
    openEventModal,
}: StartseiteNextEventCardProps) => {
    const isLoggedIn = useAppSelector(userIsLoggedIn);
    const canEdit = useAppSelector(userCanEditContent);

    const dateObj = new Date(event.date * 1000);

    const day: string =
        dateObj.getUTCDate() < 10
            ? "0" + dateObj.getUTCDate()
            : dateObj.getUTCDate() + "";
    const month: string =
        dateObj.getUTCMonth() + 1 < 10
            ? "0" + (dateObj.getUTCMonth() + 1)
            : dateObj.getUTCMonth() + 1 + "";

    const inNext3Days: boolean =
        Date.now() >= (event.date - 60 * 60 * 24 * 3) * 1000;

    const onPublishClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        publishEvent(event.id);
    };

    const onDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        deleteEvent(event.id);
    };

    const onClickCard = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        openEventModal(event);
    };

    return (
        <>
            <div
                className={classNames("StartseiteNextEventCard", {
                    "StartseiteNextEventCard--red": inNext3Days,
                    "StartseiteNextEventCard--draft": event.draft === 1,
                })}
                onClick={onClickCard}
            >
                <div className="StartseiteNextEventCard__date center-content">
                    {day}.<br />
                    {month}.
                </div>
                <div className="StartseiteNextEventsCard__title center-content">
                    <span className="l1-sb">{event.title}</span>
                </div>
                {isLoggedIn && canEdit ? (
                    <ContentActionButtons
                        onEditClick={(e) => {}}
                        onDeleteClick={onDeleteClick}
                        onPublishClick={
                            event.draft === 1 ? onPublishClick : null
                        }
                    ></ContentActionButtons>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default StartseiteNextEventCard;
