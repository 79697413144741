import xss from "xss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    fetchProjekte,
    selectAllProjekte,
} from "../../features/projekte/projekteSlice";
import { AlertVariant } from "../../enums/AlertVariant";
import { RequestStatus } from "../../enums/RequestStatus";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { alertAdded } from "../../features/alerts/alertsSlice";
import { userCanEditContent } from "../../features/user/userSlice";
import { ProjekteService } from "../../services/projekteService/ProjekteService";

import ArticleCardList from "../../components/common/articleCardList/ArticleCardList";
import ConfirmationModal from "../../components/common/confirmationModal/ConfirmationModal";
import ArticleCardSkeleton from "../../components/common/articleCardSkeleton/ArticleCardSkeleton";
import FloatingActionButton from "../../components/common/buttons/floatingActionButton/FloatingActionButton";

import { ReactComponent as PlusIcon } from "./../../assets/resources/icons/plus.svg";
import { ReactComponent as TrashIcon } from "./../../assets/resources/icons/trash.svg";

const Projekte = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userCanEdit = useSelector(userCanEditContent);

    const projekte = useAppSelector((state) => selectAllProjekte(state));

    const projekteStatus = useAppSelector((state) => state.projekte.status);
    const error = useAppSelector((state) => state.projekte.error);

    useEffect(() => {
        if (error) {
            dispatch(
                alertAdded({
                    text: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                    variant: AlertVariant.Danger,
                })
            );

            console.error(error);
        }
    }, [error]);

    const [projektIdToDelete, setProjektIdToDelete] = useState<number | null>(
        null
    );
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState<boolean>(false);

    useEffect((): void => {
        if (projekteStatus === RequestStatus.Idle) {
            dispatch(fetchProjekte());
        }
    }, [projekteStatus, dispatch]);

    const onProjektClick = (id: string) => (): void => {
        const safeId = xss(id);
        const convertedId = parseInt(safeId);
        navigate(`/projekte/${convertedId}`);
    };

    //TODO: Bessere Fehlermeldungen
    if (projekteStatus === RequestStatus.Failed) {
        return (
            <div>
                <span>Seite konnte nicht geladen werden!</span>
            </div>
        );
    }

    const publishProjekt = async (id: number): Promise<void> => {
        const result = await ProjekteService.publish(id);
        if (result) dispatch(fetchProjekte());
    };

    const deleteProjekt = (id: number): void => {
        setProjektIdToDelete(id);
        setShowDeleteConfirmationModal(true);
    };

    const onDeleteProjektConfirmed = async (): Promise<void> => {
        if (projektIdToDelete === null) return;

        setShowDeleteConfirmationModal(false);

        const successful = await ProjekteService.delete(projektIdToDelete);

        if (successful) {
            dispatch(
                alertAdded({
                    text: "Projekt gelöscht",
                    variant: AlertVariant.Success,
                })
            );
            dispatch(fetchProjekte());
        } else {
            dispatch(
                alertAdded({
                    text: "Projekt konnte nicht gelöscht werden",
                    variant: AlertVariant.Danger,
                })
            );
        }
    };

    const onAddNewProjektBtnClick = (): void => {
        navigate("/admin/add/projekt");
    };

    return (
        <>
            <div className="mb-5">
                <h1 className="text-center">Projekte</h1>
                {projekteStatus === RequestStatus.Succeeded &&
                projekte.length === 0 ? (
                    <div className="text-center">
                        <i>Keine Einträge vorhanden</i>
                    </div>
                ) : (
                    <></>
                )}
                {projekteStatus === RequestStatus.Succeeded ? (
                    <ArticleCardList
                        articles={projekte}
                        onArticleClick={onProjektClick}
                        publishArticle={publishProjekt}
                        deleteArticle={deleteProjekt}
                    ></ArticleCardList>
                ) : (
                    <ArticleCardSkeleton></ArticleCardSkeleton>
                )}
                {userCanEdit ? (
                    <FloatingActionButton
                        description="Neuen Eintrag hinzufügen"
                        onClick={onAddNewProjektBtnClick}
                        Icon={PlusIcon}
                    ></FloatingActionButton>
                ) : (
                    <></>
                )}
                {showDeleteConfirmationModal && userCanEdit ? (
                    <ConfirmationModal
                        title="Löschen bestätigen"
                        content="Möchten Sie das Projekt wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden."
                        setShow={setShowDeleteConfirmationModal}
                        show={showDeleteConfirmationModal}
                        onCancel={() => setShowDeleteConfirmationModal(false)}
                        onConfirm={() => onDeleteProjektConfirmed()}
                        actionBtnLabel="Löschen"
                        ActionBtnIcon={TrashIcon}
                    ></ConfirmationModal>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};

export default Projekte;
