import { useNavigate } from "react-router-dom";
import EditPage from "../../components/admin/editPage/EditPage";
import { useAppSelector } from "../../hooks";
import { userIsLoggedIn } from "../../features/user/userSlice";
import { useEffect } from "react";

const AdminAdd = () => {
    const navigate = useNavigate();
    const isLoggedIn = useAppSelector(userIsLoggedIn);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoggedIn === false) {
                navigate("/admin");
            }
        }, 400);

        return () => clearTimeout(timer);
    }, [isLoggedIn]);

    return (
        <>
            <EditPage postId={null}></EditPage>
        </>
    );
};

export default AdminAdd;
