import SlateEditorToolbarBtn, {
    SlateEditorToolbarBtnProps,
} from "./components/slateEditorToolbarBtn/SlateEditorToolbarBtn";
import SlateEditorToolbarImageBtn from "./components/slateEditorToolbarImageBtn/SlateEditorToolbarImageBtn";
import { SlateEditorFormatType } from "../../../../../enums/SlateEditorFormatType";

import { ReactComponent as BoldIcon } from "../../../../../assets/resources/icons/bold.svg";
import { ReactComponent as ItalicIcon } from "../../../../../assets/resources/icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "../../../../../assets/resources/icons/underline.svg";
import { ReactComponent as CodeIcon } from "../../../../../assets/resources/icons/code-simple.svg";
import { ReactComponent as HeadingOneIcon } from "../../../../../assets/resources/icons/heading.svg";
import { ReactComponent as HeadingTwoIcon } from "../../../../../assets/resources/icons/heading-2.svg";
import { ReactComponent as QuoteRightIcon } from "../../../../../assets/resources/icons/quote-right.svg";
import { ReactComponent as ListIcon } from "../../../../../assets/resources/icons/list.svg";
import { ReactComponent as AlignLeftIcon } from "../../../../../assets/resources/icons/align-left.svg";
import { ReactComponent as AlignCenterIcon } from "../../../../../assets/resources/icons/align-center.svg";
import { ReactComponent as AlignRightIcon } from "../../../../../assets/resources/icons/align-right.svg";
import { ReactComponent as AlignJustifyIcon } from "../../../../../assets/resources/icons/align-justify.svg";

const toolbarConfig: SlateEditorToolbarBtnProps[] = [
    {
        isMark: true,
        format: SlateEditorFormatType.Bold,
        Icon: BoldIcon,
    },
    {
        isMark: true,
        format: SlateEditorFormatType.Italic,
        Icon: ItalicIcon,
    },
    {
        isMark: true,
        format: SlateEditorFormatType.Underline,
        Icon: UnderlineIcon,
    },

    {
        isMark: true,
        format: SlateEditorFormatType.Code,
        Icon: CodeIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.HeadingOne,
        Icon: HeadingOneIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.HeadingTwo,
        Icon: HeadingTwoIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.BlockQuote,
        Icon: QuoteRightIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.NumberedList,
        Icon: ListIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.BulletedList,
        Icon: ListIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.AlignLeft,
        Icon: AlignLeftIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.AlignCenter,
        Icon: AlignCenterIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.AlignRight,
        Icon: AlignRightIcon,
    },
    {
        isMark: false,
        format: SlateEditorFormatType.AlignJustify,
        Icon: AlignJustifyIcon,
    },
];

export interface SlateEditorToolbarProps {
    setShowUploadImageModal: Function;
}

const SlateEditorToolbar = ({
    setShowUploadImageModal,
}: SlateEditorToolbarProps) => {
    const onAddImageClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        setShowUploadImageModal(true);
    };

    return (
        <>
            <div className="SlateEditorToolbar">
                {toolbarConfig.map((props, index) => (
                    <SlateEditorToolbarBtn
                        key={`key-toolbar-btn-${index}`}
                        {...props}
                    ></SlateEditorToolbarBtn>
                ))}
                <SlateEditorToolbarImageBtn
                    onClick={onAddImageClick}
                ></SlateEditorToolbarImageBtn>
            </div>
        </>
    );
};

export default SlateEditorToolbar;
