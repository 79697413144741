export interface SquareImgProps {
    image: string;
    alt: string;
    size: {
        width: string;
        height: string;
    };
}

const SquareImg = ({ image, alt, size }: SquareImgProps) => {
    return (
        <>
            <div className="clearfix" style={size}>
                <img className="d-block w-auto h-100" src={image} alt={alt} />
            </div>
        </>
    );
};

export default SquareImg;
