export interface FloatingActionButtonProps {
    description: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    > | null;
}

const FloatingActionButton = ({
    description,
    onClick,
    Icon,
}: FloatingActionButtonProps) => {
    return (
        <>
            <button className="FloatingActionButton" onClick={onClick}>
                <span className="d-none">{description}</span>
                {Icon ? (
                    <Icon className="FloatingActionButton__icon"></Icon>
                ) : (
                    <></>
                )}
            </button>
        </>
    );
};

export default FloatingActionButton;
