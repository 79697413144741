import { EditPageOptionDataFieldType } from "../../../enums/EditPageOptionDataFieldType";
import { EditPageOption } from "../../../interfaces/IEditPageOption";

const EditPageOptions: EditPageOption[] = [
    {
        name: "projekt",
        friendlyName: "Projekt",
        dataFields: [
            {
                name: "id",
                friendlyName: "Eintrags-ID",
                type: EditPageOptionDataFieldType.Number,
                disabled: true,
            },
            {
                name: "title",
                friendlyName: "Titel",
                type: EditPageOptionDataFieldType.String,
            },
            {
                name: "previewText",
                friendlyName: "Vorschautext",
                type: EditPageOptionDataFieldType.Text,
            },
            {
                name: "content",
                friendlyName: "Inhalt",
                type: EditPageOptionDataFieldType.RichText,
            },
            {
                name: "galeryImagesUpload",
                friendlyName: "Galeriebilder-Upload",
                type: EditPageOptionDataFieldType.ImageDropzone,
            },
            {
                name: "galeryImagesPreview",
                friendlyName: "Galeriebilder-Vorschau",
                type: EditPageOptionDataFieldType.ImagePreview,
            },
        ],
    },
    {
        name: "ankuendigung",
        friendlyName: "Ankündigung",
        dataFields: [
            {
                name: "id",
                friendlyName: "Eintrags-ID",
                type: EditPageOptionDataFieldType.Number,
                disabled: true,
            },
            {
                name: "title",
                friendlyName: "Titel",
                type: EditPageOptionDataFieldType.String,
            },
            {
                name: "previewText",
                friendlyName: "Vorschautext",
                type: EditPageOptionDataFieldType.Text,
            },
            {
                name: "content",
                friendlyName: "Inhalt",
                type: EditPageOptionDataFieldType.RichText,
            },
            {
                name: "galeryImagesUpload",
                friendlyName: "Galeriebilder-Upload",
                type: EditPageOptionDataFieldType.ImageDropzone,
            },
            {
                name: "galeryImagesPreview",
                friendlyName: "Galeriebilder-Vorschau",
                type: EditPageOptionDataFieldType.ImagePreview,
            },
        ],
    },
    {
        name: "ereignis",
        friendlyName: "Ereignis",
        dataFields: [
            {
                name: "id",
                friendlyName: "Eintrags-ID",
                type: EditPageOptionDataFieldType.Number,
                disabled: true,
            },
            {
                name: "date",
                friendlyName: "Datum",
                type: EditPageOptionDataFieldType.Date,
            },
            {
                name: "title",
                friendlyName: "Titel",
                type: EditPageOptionDataFieldType.String,
            },
            {
                name: "description",
                friendlyName: "Beschreibung",
                type: EditPageOptionDataFieldType.Text,
            },
        ],
    },
    {
        name: "satzung",
        friendlyName: "Satzung",
        dataFields: [
            {
                name: "id",
                friendlyName: "Eintrags-ID",
                type: EditPageOptionDataFieldType.Number,
                disabled: true,
            },
            {
                name: "title",
                friendlyName: "Titel",
                type: EditPageOptionDataFieldType.String,
            },
            {
                name: "document",
                friendlyName: "Dokument-Upload",
                type: EditPageOptionDataFieldType.DocumentDropzone,
            },
        ],
    },
    {
        name: "user",
        friendlyName: "Nutzer",
        dataFields: [
            {
                name: "id",
                friendlyName: "Nutzer-ID",
                type: EditPageOptionDataFieldType.Number,
                disabled: true,
            },
            {
                name: "username",
                friendlyName: "Nutzername",
                type: EditPageOptionDataFieldType.String,
            },
            {
                name: "email",
                friendlyName: "E-Mail (optional für Benachrichtigungen)",
                type: EditPageOptionDataFieldType.String,
                optional: true,
            },
            {
                name: "password",
                friendlyName: "Passwort",
                type: EditPageOptionDataFieldType.Password,
            },
            {
                name: "is_admin",
                friendlyName: "Adminrechte",
                type: EditPageOptionDataFieldType.Checkbox,
            },
            {
                name: "only_read",
                friendlyName: "Ausschließlich Leserechte",
                type: EditPageOptionDataFieldType.Checkbox,
            },
        ],
    },
];

export default EditPageOptions;
