import { ButtonType } from "../../../enums/ButtonType";
import IconButton from "../../common/buttons/iconButton/IconButton";
import HorizontalLine from "../../common/horizontalLine/HorizontalLine";
import { ReactComponent as TrashIcon } from "../../../assets/resources/icons/trash.svg";
import { ReactComponent as CheckIcon } from "../../../assets/resources/icons/check.svg";
import { useEffect, useState } from "react";
import EditPageOptions from "./EditPageOptions";
import EditPagePropertyMapping from "./EditPagePropertyMapping";
import { IUploadProjekt } from "../../../interfaces/upload/IUploadProjekt";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ProjekteService } from "../../../services/projekteService/ProjekteService";
import { fetchProjekte } from "../../../features/projekte/projekteSlice";
import { useParams } from "react-router-dom";
import { AnkuendigungenService } from "../../../services/ankuendigungenService/AnkuendigungenService";
import { fetchAnkuendigungen } from "../../../features/ankuendigungen/ankuendigungenSlice";
import { IUploadAnkuendigung } from "../../../interfaces/upload/IUploadAnkuendigung";
import { IUploadSatzung } from "../../../interfaces/upload/IUploadSatzung";
import { NaechsteEventsService } from "../../../services/naechsteEventsService/NaechsteEventsService";
import { IUploadEvent } from "../../../interfaces/upload/IUploadEvent";
import { fetchNaechsteEvents } from "../../../features/naechsteEvents/naechsteEventsSlice";
import { fetchSatzungen } from "../../../features/satzungen/satzungenSlice";
import { SatzungenService } from "../../../services/satzungenService/SatzungenService";
import { alertAdded } from "../../../features/alerts/alertsSlice";
import { AlertVariant } from "../../../enums/AlertVariant";
import { IUploadUser } from "../../../interfaces/upload/IUploadUser";
import UserService from "../../../services/userService/UserService";

export interface EditPageProps {
    postId: number | null;
}

const EditPage = ({ postId }: EditPageProps) => {
    const { type } = useParams();

    const dispatch = useAppDispatch();
    const currentUsername = useAppSelector((state) => state.user.username);

    const [saveAsDraft, setSaveAsDraft] = useState<boolean>(false);
    const [selectType, setSelectType] = useState<number | null>(null);

    const [data, setData] = useState<
        | null
        | IUploadProjekt
        | IUploadAnkuendigung
        | IUploadEvent
        | IUploadSatzung
        | IUploadUser
    >(null);

    // check if post should be created of edited
    const createPost: boolean = postId === null;

    const resetForm = () => {
        setData(null);
        setSelectType(null);
    };

    const onBtnCancelClick = () => {
        // TODO: Add confirm modal

        resetForm();

        dispatch(
            alertAdded({
                text: "Formular wurde zurückgesetzt.",
                variant: AlertVariant.Warning,
            })
        );
    };

    const onChangeSelectType = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        if (event.target.selectedIndex > 0) {
            setSelectType(event.target.selectedIndex - 1);
        } else {
            setSelectType(null);
        }
    };

    const onSaveAsDraftCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => setSaveAsDraft(event.target.checked);

    const onFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>
    ): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (selectType === null) return;

        const configuration = EditPageOptions[selectType];

        let result: boolean;

        // TODO: Alert confirmation
        // TODO: Error handling

        switch (configuration.name) {
            case "projekt":
                result = await ProjekteService.add(
                    data as IUploadProjekt,
                    currentUsername
                );

                if (result) {
                    dispatch(fetchProjekte());
                    resetForm();
                    dispatch(
                        alertAdded({
                            text: `Projekt wurde hochgeladen.`,
                            variant: AlertVariant.Success,
                        })
                    );
                } else {
                    dispatch(
                        alertAdded({
                            text: "Es ist ein Fehler aufgetreten!",
                            variant: AlertVariant.Danger,
                        })
                    );
                }

                break;
            case "ankuendigung":
                result = await AnkuendigungenService.add(
                    data as IUploadAnkuendigung,
                    currentUsername
                );

                if (result) {
                    dispatch(fetchAnkuendigungen());
                    resetForm();
                    dispatch(
                        alertAdded({
                            text: `Ankündigung wurde hochgeladen.`,
                            variant: AlertVariant.Success,
                        })
                    );
                } else {
                    dispatch(
                        alertAdded({
                            text: "Es ist ein Fehler aufgetreten!",
                            variant: AlertVariant.Danger,
                        })
                    );
                }

                break;
            case "ereignis":
                result = await NaechsteEventsService.add(data as IUploadEvent);

                if (result) {
                    dispatch(fetchNaechsteEvents());
                    resetForm();
                    dispatch(
                        alertAdded({
                            text: `Event wurde hochgeladen.`,
                            variant: AlertVariant.Success,
                        })
                    );
                } else {
                    dispatch(
                        alertAdded({
                            text: "Es ist ein Fehler aufgetreten!",
                            variant: AlertVariant.Danger,
                        })
                    );
                }

                break;
            case "satzung":
                result = await SatzungenService.add(data as IUploadSatzung);

                if (result) {
                    dispatch(fetchSatzungen());
                    resetForm();
                    dispatch(
                        alertAdded({
                            text: `Satzung wurde hochgeladen.`,
                            variant: AlertVariant.Success,
                        })
                    );
                } else {
                    dispatch(
                        alertAdded({
                            text: "Es ist ein Fehler aufgetreten!",
                            variant: AlertVariant.Danger,
                        })
                    );
                }

                break;
            case "user":
                result = await UserService.createUser(data as IUploadUser);

                if (result) {
                    resetForm();
                    dispatch(
                        alertAdded({
                            text: `Nutzeraccount wurde erstellt.`,
                            variant: AlertVariant.Success,
                        })
                    );
                } else {
                    dispatch(
                        alertAdded({
                            text: "Es ist ein Fehler aufgetreten!",
                            variant: AlertVariant.Danger,
                        })
                    );
                }

                break;
        }
    };

    useEffect(() => {
        // Change select type based on url
        if (type === undefined) return;

        const choosenIndex = EditPageOptions.findIndex(
            (element) => element.name === type
        );

        setSelectType(choosenIndex !== -1 ? choosenIndex : null);
    }, [type]);

    useEffect(() => {
        // Reset data object

        if (selectType === null) {
            // Nothing is selected
            setData(null);
            return;
        }

        switch (EditPageOptions[selectType].name) {
            case "projekt":
                setData({
                    id: null,
                    title: "",
                    previewText: "",
                    content: [],
                    galeryImages: [],
                    draft: saveAsDraft,
                });
                break;
            case "ankuendigung":
                setData({
                    id: null,
                    title: "",
                    previewText: "",
                    content: [],
                    galeryImages: [],
                    draft: saveAsDraft,
                });
                break;
            case "ereignis":
                setData({
                    id: null,
                    date: Math.round(Date.now() / 1000),
                    title: "",
                    description: "",
                    draft: saveAsDraft,
                });
                break;
            case "satzung":
                setData({
                    id: null,
                    title: "",
                    document: null,
                    draft: saveAsDraft,
                });
                break;
            case "user":
                setData({
                    id: null,
                    username: "",
                    email: "",
                    password: "",
                    is_admin: false,
                    only_read: false,
                });
        }
    }, [selectType]);

    useEffect(() => {
        const prop = "draft" as keyof typeof data;

        if (data && data[prop] !== undefined) {
            setData({ ...data, [prop]: saveAsDraft });
        }
    }, [saveAsDraft]);

    /* useEffect(() => console.log(data), [data]); */

    // TODO: DefaultValue

    return (
        <>
            <div className="EditPage mb-5">
                <form onSubmit={onFormSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">
                                Neuen Eintrag erstellen
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label
                                className="form-label l2-sb mb-2"
                                htmlFor="select-add-type"
                            >
                                Was soll hinzugefügt werden?
                            </label>
                            <select
                                id="select-add-type"
                                className="form-select"
                                aira-label="Auswahl des Eintragtyps"
                                onChange={onChangeSelectType}
                                defaultValue={type}
                            >
                                <option value="">Bitte auswählen</option>
                                {EditPageOptions.map((option, index) => (
                                    <option
                                        key={`key-select-type-${option.name}`}
                                        value={option.name}
                                    >
                                        {option.friendlyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <HorizontalLine></HorizontalLine>
                        </div>
                    </div>
                    {selectType !== null ? (
                        <EditPagePropertyMapping
                            selectedIndex={selectType}
                            data={data}
                            setData={setData}
                        ></EditPagePropertyMapping>
                    ) : (
                        <div className="my-4">
                            <i>Bitte einen Typ auswählen!</i>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <div className="add-post-save-as-draft form-check rounded mb-3 px-5 py-4">
                                <input
                                    id="add-post-save-as-draft-checkbox"
                                    type="checkbox"
                                    className="form-check-input"
                                    value=""
                                    onChange={onSaveAsDraftCheckboxChange}
                                />
                                <label
                                    className="form-check-label-checkbox"
                                    htmlFor="add-post-save-as-draft"
                                >
                                    Als Entwurf speichern
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <IconButton
                                title="Abbrechen"
                                onClick={onBtnCancelClick}
                                type={ButtonType.Reset}
                                Icon={TrashIcon}
                                primary={false}
                            ></IconButton>
                        </div>
                        <div className="col-6">
                            <IconButton
                                title={
                                    saveAsDraft
                                        ? "Als Entwurf speichern"
                                        : "Veröffentlichen"
                                }
                                onClick={() => {}}
                                type={ButtonType.Submit}
                                Icon={CheckIcon}
                                primary={true}
                            ></IconButton>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditPage;
