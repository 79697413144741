export interface MobileNavigationButtonProps {
    onClick: Function;
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }
    > | null;
}

const MobileNavigationButton = ({
    onClick,
    Icon,
}: MobileNavigationButtonProps) => {
    return (
        <>
            <button
                className="MobileNavigationButton"
                onClick={() => onClick()}
            >
                {Icon ? (
                    <Icon className="MobileNavigationButton__icon"></Icon>
                ) : (
                    <></>
                )}
            </button>
        </>
    );
};

export default MobileNavigationButton;
