import StartseiteHeaderCard from "./components/StartseiteHeaderCard/StartseiteHeaderCard";

import { ReactComponent as EuroIcon } from "./../../../../assets/resources/icons/euro.svg";
import { ReactComponent as UserIcon } from "./../../../../assets/resources/icons/user.svg";
import { ReactComponent as BackpackIcon } from "./../../../../assets/resources/icons/backpack.svg";

//TODO: ALT for images
const StartseiteCardSection = () => {
    return (
        <>
            <div className="d-none d-lg-flex justify-content-between">
                <div className="col-3">
                    <StartseiteHeaderCard
                        title="105+ Mitglieder"
                        text="Unser Verein zählt derzeit mehr als 105 Mitglieder, dazu gehören alle Lehrer, einige Erzieherinnen sowie verschiedene Eltern und Großeltern. Mit Ihrer Hilfe möchten wir uns weiter vergrößern!"
                        Icon={UserIcon}
                    ></StartseiteHeaderCard>
                </div>
                <div className="col-3">
                    <StartseiteHeaderCard
                        title="Zahlreiche Projekte"
                        text="Wir haben in den letzten Jahren zahlreiche Projekte unterstützt, wie Fahrten ins Theater, Beschaffung von Büchern, Ausgestaltung des Schulhofs und der Klassenzimmer und vieles mehr."
                        Icon={BackpackIcon}
                    ></StartseiteHeaderCard>
                </div>
                <div className="col-3">
                    <StartseiteHeaderCard
                        title="Nur 7€ Jahresbeitrag"
                        text="Wir freuen uns über jedes Mitglied. Das Maß der Mitarbeit bestimmt jeder selbst! Mancher bezahlt nur 7 Euro Jahresbeitrag, andere liefern viele Ideen und bringen sich aktiv in den Verein ein."
                        Icon={EuroIcon}
                    ></StartseiteHeaderCard>
                </div>
            </div>
            <div className="col-12 d-lg-none my-4">
                <StartseiteHeaderCard
                    title="105+ Mitglieder"
                    text="Unser Verein zählt derzeit mehr als 105 Mitglieder, dazu gehören alle Lehrer, einige Erzieherinnen sowie verschiedene Eltern und Großeltern. Mit Ihrer Hilfe möchten wir uns weiter vergrößern!"
                    Icon={UserIcon}
                ></StartseiteHeaderCard>
            </div>
            <div className="col-12 d-lg-none my-4">
                <StartseiteHeaderCard
                    title="Zahlreiche Projekte"
                    text="Wir haben in den letzten Jahren zahlreiche Projekte unterstützt, wie Fahrten ins Theater, Beschaffung von Büchern, Ausgestaltung des Schulhofs und der Klassenzimmer und vieles mehr."
                    Icon={BackpackIcon}
                ></StartseiteHeaderCard>
            </div>
            <div className="col-12 d-lg-none my-4">
                <StartseiteHeaderCard
                    title="Nur 7€ Jahresbeitrag"
                    text="Wir freuen uns über jedes Mitglied. Das Maß der Mitarbeit bestimmt jeder selbst! Mancher bezahlt nur 7 Euro Jahresbeitrag, andere liefern viele Ideen und bringen sich aktiv in den Verein ein."
                    Icon={EuroIcon}
                ></StartseiteHeaderCard>
            </div>
        </>
    );
};

export default StartseiteCardSection;
