import { useState } from "react";
import INavigationPage from "../../interfaces/INavigationPage";
import { ReactComponent as CrossIcon } from "./../../../../assets/resources/icons/cross.svg";
import MobileNavigationButton from "../mobileNavigationButton/MobileNavigationButton";
import MobileNavigationItem from "./components/mobileNavigationItem/MobileNavigationItem";
import { useLocation } from "react-router-dom";
import xss from "xss";
import classNames from "classnames";

export interface MobileNavigationProps {
    navigationPagesMobile: INavigationPage[];
    showMobileNavigation: boolean;
    setShowMobileNavigation: Function;
}

const MobileNavigation = ({
    navigationPagesMobile,
    showMobileNavigation,
    setShowMobileNavigation,
}: MobileNavigationProps) => {
    const location = useLocation();
    const [blendOut, setBlendOut] = useState(false);

    const closeMobileNavigation = () => {
        setBlendOut(true);
        setTimeout(() => {
            setBlendOut(false);
            setShowMobileNavigation(false);
        }, 1000);
    };

    return (
        <>
            <div
                className={classNames("MobileNavigation animate__animated", {
                    animate__fadeInUp: showMobileNavigation,
                    animate__fadeOutDown: blendOut,
                })}
            >
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-center">Menü</h2>
                        </div>
                    </div>
                    <ul className="m-0 p-0">
                        {navigationPagesMobile.map((item, index) => (
                            <MobileNavigationItem
                                key={`key-mobile-nav-${index}`}
                                item={item}
                                active={xss(location.pathname) === item.route}
                                closeMobileNavigation={closeMobileNavigation}
                            ></MobileNavigationItem>
                        ))}
                    </ul>
                    <div className="MobileNavigation__closeButton">
                        <MobileNavigationButton
                            onClick={closeMobileNavigation}
                            Icon={CrossIcon}
                        ></MobileNavigationButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileNavigation;
