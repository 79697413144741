const Impressum = () => {
  return (
    <>
      <div className="mb-5">
        <h1>Impressum</h1>
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          F&ouml;rderverein der Grundschule Otterwisch e.V.
          <br />
          Stockheimer Stra&szlig;e 6<br />
          04668 Otterwisch
        </p>
        <p>
          Vereinsregister: VR 20825
          <br />
          Registergericht: Amtsgericht Leipzig
        </p>
        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Juliane Staudte-Schuster (Vorstand)
          <br />
          Kora Gl&ouml;ckner (stellv. Vorstand)
          <br />
          Anja Grohme (Schatzmeisterin)
          <br />
          Susen Gro&szlig;mann (Schriftf&uuml;hrerin)
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +49 174 2972488
          <br />
          E-Mail: foerderverein.gs.otterwisch@gmail.com
        </p>
        <h2>Redaktionell verantwortlich</h2>
        <p>Juliane Staudte-Schuster</p>
        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
          Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
        </p>
      </div>
      <div className="mb-5">
        <h1>Credits</h1>
        <p>
          UIcons by <a href="https://www.flaticon.com/uicons">Flaticon</a>
        </p>

        <strong>
          Die Website wurde entworfen und entwickelt von Lennox S.
        </strong>
      </div>
    </>
  );
};

export default Impressum;
